@use "../globals";
#products-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-color: var(--AvvicWhite);
	#entry {
		text-align: center;
		padding: 2rem;
		@media (min-width: 49.75em) {
			padding: 3rem 5rem;
		}
		@media (min-width: 64.1em) {
			padding: 3rem 20rem;
		}
		h2 {
			color: var(--AvvicDBlue);
			margin-bottom: 1rem;
		}
	}
	#products-main {
		display: flex;
		.all-products {
			width: 100%;
		}
		.products-display {
			padding-inline: 3%;
			@media (min-width: 49.75em) {
				padding-inline: 10%;
			}
		}
	}
}
