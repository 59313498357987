@use "../globals";

#ctaBanner {
	background: linear-gradient(
		90deg,
		rgba(2, 11, 50, 1) 0%,
		rgba(13, 153, 153, 1) 55%,
		rgba(18, 204, 204, 1) 84%
	);
	padding: 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem;
	div {
		margin: 0 auto;
	}
	@media (min-width: 49.75em) {
		padding: 4rem;
	}

	h2 {
		color: var(--AvvicWhite);
		font-weight: var(--headings-font-weight);
		text-align: center;
		padding: 0;
		margin: 0;
		@media (min-width: 49.75em) {
			text-align: start;
		}
	}
	button {
		background-color: var(--AvvicWhite);
		color: var(--AvvicDCyan);
		border: none;
		font-size: var(--text-size);
		width: var(--normal-btn-width);
	}
}
