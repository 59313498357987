@use "../../globals";
.request-forms-top {
	display: flex;
	flex-direction: column;
	padding-inline: 3%;
	gap: 2rem;
	@media (min-width: 49.75em) {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-column: span 4;
	}
	@media (min-width: 49.75em) {
		.form-stepper-container {
			grid-column: span 38;
		}
	}
}

.request-forms-wrappers {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding-inline: 3%;
	gap: 2rem;
	.success {
		color: var(--Avvic-Blue-Pri, var(--Avvic-pry, #0d9999));
		text-align: center;
		font-size: 60px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.form-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 2rem;
		gap: 2rem;
		.form-item {
			margin-top: 40px;
		}
		.request-form-container {
			position: absolute;
			display: flex;
			gap: 15px;
			flex-direction: column;
			justify-content: center;
			margin-left: 310px;
			margin-top: 70px;
		}
		.form-separator {
			text-align: center;
			font-size: 20px;
			margin-top: -50px;
		}
		.add-more-intro {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		@media (min-width: 49.75em) {
			gap: 2rem;
		}
		h3,
		h4,
		h5 {
			color: var(--AvvicDBlue);
		}
		h5 {
			margin-bottom: 1rem;
		}
		h3 {
			text-align: center;
		}
		a {
			text-decoration: none;
		}
		.input-label {
			color: #212121;
		}
		.other-banks {
			width: 100%;
			padding: 0.7rem;
			border-radius: 8px;
			border: 1px solid var(--Avvic-light-grey);
			margin-top: 10px;
			text-transform: uppercase;
			color: #878e9d;
			margin-bottom: 10px;
		}
		.request-heading {
			color: var(--neutral-black, #212121);
			font-size: 32px;
			font-style: normal;
			font-weight: 700;
			line-height: 160%;
		}
		.select-account {
			display: flex;
			gap: 10px;
			justify-content: center;
			align-items: center;
			margin: 30px;
		}

		.choose-account {
			color: #667085;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 25.6px;
		}

		.bank-select {
			width: 311px;
			height: 40px;
			border-radius: 8px;
			border: 1px solid #d0d5dd;
			background: #fff;
			box-shadow: 0px 1.04009px 2.08017px 0px rgba(16, 24, 40, 0.05);
		}

		.required-info {
			color: var(--neutral-black, #212121);
			margin: 3px;
			font-size: 20.802px;
			font-style: normal;
			font-weight: 700;
			line-height: 31.203px;
		}

		.another-email-div {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-top: -50px;
			margin-bottom: 5px;
		}

		.recipient-info {
			color: var(--neutral-black, #212121);
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			display: flex;
			justify-content: center;
			line-height: 160%;
			margin: 30px;
			margin-left: 107px;
		}

		.table-line {
			stroke-width: 1px;
			stroke: var(--textfield-stroke, #d0d5dd);
			margin-top: -30px;
			margin-bottom: 17px;
		}

		.request-form {
			.another-email {
				color: var(--neutral-black, #212121);
				font-size: 19px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%;
			}
			.another-email-div {
				display: flex;
				gap: 5px;
				align-items: center;
				justify-content: flex-start;
				margin-top: -14px;
			}
		}
		form {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			margin-bottom: 50px;

			button {
				background-color: var(--AvvicDCyan);
				color: var(--AvvicWhite);
				padding: 0.7rem;
				border: none;
				font-size: 18px;
				font-style: normal;
				line-height: 160%;
			}
			textarea {
				border-radius: 8px;
				border: 1px solid var(--Avvic-light-grey);
				resize: none;
				width: 100%;
				padding: 15px;
				margin-top: 10px;
			}
			.blue {
				color: #2c7aea;
			}
			.two-input-div {
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
				gap: 1rem;
				margin-bottom: 16px;
				@media (min-width: 49.75em) {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					gap: 1rem;
				}
			}
			.two-btn-div {
				display: flex;
				gap: 1rem;
				button {
					width: 100%;
				}
				.prev-btn {
					border: 1px solid var(--Avvic-pry);
					background-color: transparent;
					color: var(--Avvic-pry);
				}
			}
		}
	}
	.transaction-summary-container {
		grid-column: 2/3;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-top: 8rem;
		padding-inline: 3%;

		.confirm-heading {
			color: var(--neutral-black, #212121);
			font-size: 32px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.113px;
		}
		.recipient-info {
			color: var(--neutral-black, #212121);
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 160%;
		}
		.confirm-buttons {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 32px;
		}
		.previous-btn {
			padding: 11.5px 24px;
			width: 290.5px;
			height: 52px;
			border-radius: 100px;
			border: 1px solid var(--Avvic-Blue-Pri, #0d9999);
			box-shadow: 0px 0.96021px 1.92041px 0px rgba(16, 24, 40, 0.05);
			color: var(--Avvic-Blue-Pri, var(--Avvic-pry, #0d9999));
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 28.8px;
			background: white;
		}
		.submit-btn {
			padding: 11.5px 24px;
			width: 290.5px;
			height: 52px;
			border-radius: 100px;
			box-shadow: 0px 0.96021px 1.92041px 0px rgba(16, 24, 40, 0.05);
			border: 1px solid var(--Avvic-Blue-Pri, #0d9999);
			color: white;
			background: #0d9999;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 28.8px;
		}

		h3,
		h4 {
			text-align: center;
			color: var(--AvvicDBlue);
		}
		table {
			width: 100%;
			th {
				padding: 0.7rem;
				text-align: left;
			}
			td {
				text-align: right;
				padding: 0.7rem;
			}
		}
		button {
			border: none;
			color: var(--AvvicWhite);
			padding: 0.7rem;
		}
	}
	.loan-title-holder-container {
		display: flex;
		gap: 2rem;
	}
	.loan-documents-container {
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}
	.success-wrapper {
		grid-column: 2/3;
	}
}

.logo-btn-section {
	display: flex;
	justify-content: space-between;

	@media (min-width: 49.75em) {
		justify-content: start;
		flex-direction: column;
		gap: 1.5rem;
	}
	.logo-wrapper {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		.logo-text {
			color: var(--Avvic-pry);
			font-size: 1rem;
		}
	}
	a {
		text-decoration: none;
	}
	.go-back-btn {
		background-color: transparent;
		display: flex;
		gap: 0.5rem;
		border: none;
	}
}
