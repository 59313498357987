#about-wrapper {
	display: flex;
	flex-direction: column;
	gap: var(--whiteSpace);
	@media (min-width: 49.75em) {
		gap: 0rem;
	}
	#about-top-text {
		text-align: center;
		padding: 2rem;
		background-size: cover;
		@media (min-width: 49.75em) {
			padding: 3rem 5rem;
		}
		@media (min-width: 64.1em) {
			padding: 3rem 20rem;
		}
		h2 {
			color: var(--AvvicDBlue);
			margin-top: 1rem;
		}
	}
	#about {
		padding: 2rem;
		display: grid;
		position: relative;
		gap: 20%;
		@media (min-width: 49.75em) {
			gap: 0%;
		}
		.aboutText {
			align-self: center;
			position: relative;
			text-align: center;

			@media (min-width: 49.75em) {
				text-align: start;
			}
			@media (min-width: 64.1em) {
				padding-inline: 15%;
			}

			img {
				position: absolute;
				top: -30%;
				width: 70px;
			}
		}
		#about-ellipse {
			position: absolute;
			top: -5%;
			@media (min-width: 49.75em) {
				max-width: 400px;
				left: 55%;
			}
		}
		h2 {
			color: var(--AvvicDBlue);
			margin-bottom: 1rem;
		}
		@media (min-width: 49.75em) {
			grid-template-columns: 50% 50%;
		}
	}
	#ourValues-wrapper {
		display: flex;
		flex-direction: column;
		background: linear-gradient(90deg, #fffeff 0%, #d7fffe 100%);
		gap: 2rem;
		padding: 2rem;
		@media (min-width: 49.75em) {
			padding: var(--whiteSpace) 8rem;
		}
		#our_values_header {
			h2 {
				margin-bottom: 2rem;
			}
			p {
				padding-inline: 7%;
			}
		}
		#ourValues {
			display: flex;
			gap: 2rem;
			flex-wrap: wrap;
			@media (min-width: 64.1em) {
				flex-wrap: nowrap;
			}
		}

		p {
			text-align: center;
		}
		h2,
		h3 {
			color: var(--AvvicDBlue);
			text-align: center;
		}
	}
	.image-container {
		position: relative;
		display: inline-block;
		z-index: 1;
		margin: auto;

		width: 90%;
		@media (min-width: 49.75em) {
			padding: 2%;
			// width: 70%;
		}

		img {
			width: 100%;
			display: block;
			border-radius: var(--borderRadius);
		}
		#top-right {
			background-color: rgba(13, 153, 153, 0.1);
			width: 50%;
			position: absolute;
			right: 0;
			top: 0;
			height: 80%;
			z-index: -1;
			border-top-right-radius: var(--borderRadius);
		}
		#bottom-left {
			background-color: rgba(13, 153, 153, 0.1);
			width: 50%;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 20%;
			z-index: -1;
			border-bottom-left-radius: var(--borderRadius);
		}
	}
}
