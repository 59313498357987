@use "../globals";
.modal-wrapper {
	main {
		display: grid;
		position: relative;
		padding: 1rem;
		gap: 2em;
		@media (min-width: 49.75em) {
			grid-template-columns: 40% 60%;
			padding-inline: 3%;
			justify-content: center;
			align-items: center;
		}
		.fig-img-wrapper {
			padding: 5%;
			display: inline-block;
			@media (max-width: 768px) {
				margin: 0 auto;
			}
		}
		img {
			max-width: 100%;
			max-height: 100%;
			border-radius: 5px;
			display: block;
		}
		aside {
			width: 100%;
			white-space: pre-line;
			max-height: 20rem;
			overflow-y: auto;
			// padding-right: 2rem;

			@media (min-width: 768px) {
				padding: 0 2em 0 0;
			}
			h3,
			h4 {
				text-align: start;
				margin: 0;
				button {
					border: none;
					cursor: pointer;
					background-color: transparent;
					position: absolute;
					top: 0;
					right: 0;
					@media (min-width: 49.75em) {
						top: 0%;
						right: 0%;
					}
				}
			}
			h4 {
				margin-bottom: 1rem;
			}
			p {
				font-size: 0.8em;
				text-align: justify;
			}
		}
	}
}
