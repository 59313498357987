@use "../globals";
#navbar {
	display: flex;
	justify-content: space-between;
	font-size: var(--small-text);
	padding-inline: 3%;
	position: sticky;
	top: 0;
	z-index: 4;
	width: 100%;
	background-color: var(--AvvicWhite);
	@media (min-width: 64.1em) {
		justify-content: space-between;
		align-items: center;
		padding-inline: 4.5%;
	}
	.logo-div {
		display: flex;
		flex-basis: 60%;
		align-items: center;
		img {
			max-width: 110px;
			@media (min-width: 64.1em) {
				margin-top: 2%;
				max-width: 150px;
			}
		}
	}

	.nav-links {
		display: none;
		@media (min-width: 64.1em) {
			display: flex;
			gap: 7%;
			flex-basis: 80%;
			a:hover {
				color: var(--AvvicCyan);
				transition: 0.3s ease-in-out;
			}
			a {
				text-decoration: none;
				font-size: var(--small-text);
			}

			a.activePath {
				color: var(--AvvicDCyan);
				font-weight: var(--font-weight-regular);
			}
		}
		.drop-down-link {
			cursor: pointer;
			.nav-products {
				display: flex;
				gap: 0.3rem;
				span.activePath {
					color: var(--AvvicDCyan);
				}
			}
		}
		.drop-down-menu {
			position: absolute;
			transform: scale(0);
			opacity: 0;
			transition:
				transform 0.3s,
				opacity 0.3s;
		}
		.rotate-chevron {
			transform: rotate(180deg);
			transition: rotate 0.3s;
		}
		.active-menu {
			transform: scale(1);
			opacity: 1;
			width: 10rem;
			position: absolute;
			top: 60px;
			background-color: var(--AvvicWhite);
			padding: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 0.7rem;
			border: 1px solid var(--Avvic-pry);
			border-radius: 8px;
			transition:
				transform 0.3s,
				opacity 0.3s;
			&::before {
				content: "";
				position: absolute;
				top: -10px;
				left: 10%;
				width: 17px;
				height: 17px;
				background-color: var(--AvvicWhite);
				transform: rotate(45deg);
				border-left: 1px solid var(--Avvic-pry);
				border-top: 1px solid var(--Avvic-pry);
			}
		}
	}
	.bar {
		display: block;
		width: 25px;
		height: 3px;
		margin: 5px auto;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		background-color: var(--AvvicDCyan);
		border-radius: 2px;
	}
	.nav-links-wrapper {
		flex-basis: 50%;
	}
	.nav-btn-wrapper {
		display: flex;
		gap: 1rem;
		a {
			text-decoration: none;
		}
		.nav-btn {
			display: none;
			@media (min-width: 64.1em) {
				display: block;
				padding: 0.7em 1.5em;
				background-color: var(--AvvicDCyan);
				color: var(--AvvicWhite);
				border: none;
				font-size: var(--text-size);
				border: 1px solid var(--AvvicDCyan);
				a {
					text-decoration: none;
				}
			}
		}
		.nav-btn:hover {
			background-color: #eafafa;
			color: var(--AvvicDCyan);
			transition: all 0.3s;
		}
		.nav-btn.portal-btn {
			display: none;
			@media (min-width: 64.1em) {
				background-color: var(--AvvicWhite);
				color: var(--AvvicDCyan);
				display: flex;
				gap: 0.5rem;
			}
		}
		.nav-btn.portal-btn:hover {
			background-color: #eafafa;
			transition: all 0.3s;
		}
	}
	.hamburger {
		display: block;
		background-color: transparent;
		border: none;
		@media (min-width: 64.1em) {
			display: none;
		}
	}
	.hamburger.active > .bar:nth-child(2) {
		opacity: 0;
	}
	.hamburger.active > .bar:nth-child(1) {
		transform: translateY(8px) rotate(45deg);
	}
	.hamburger.active > .bar:nth-child(3) {
		transform: translateY(-8px) rotate(-45deg);
	}
}
// MOBILE NAV
.mobile-nav {
	position: fixed;
	display: flex;
	background-color: rgba(#000, 0.4);
	height: 60px;
	gap: 0;
	right: -100%;
	top: 2.9rem;
	width: 100%;
	height: 100vh;
	text-align: left;
	transition: 0.3s;
	z-index: 99;
	// opacity: 0.9;
	@media (min-width: 64.1em) {
		display: none;
	}
	.mobile-nav-button-div {
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
		button {
			border: 1px solid var(--AvvicDCyan);
			color: var(--AvvicWhite);
			background-color: var(--AvvicWhite);
			color: var(--AvvicDCyan);
			width: var(--normal-btn-width);
		}

		button:hover {
			background-color: #eafafa;
			transition: all 0.3s;
		}
	}
	.parent-ul {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		gap: 3em;
		padding: 0;
		li {
			// border-bottom: 1px solid var(--AvvicBlack);
			width: 100%;
			a {
				display: block;
				text-decoration: none;
				font-size: var(--medium-text-size);
			}
			a.activePath,
			.activePath {
				color: var(--AvvicDBlue);
			}
		}
		.nav-products {
			display: flex;
			align-items: center;
			gap: 0.6em;
			cursor: pointer;
			span {
				margin-right: 0.3rem;
			}
		}
		.rotate-chevron {
			transform: rotate(180deg);
			transition: rotate 0.3s;
		}
		.drop-down-menu {
			display: flex;
			width: 100%;
			flex-direction: column;
			gap: 1.5rem;
			border-radius: 8px;
			padding: 0;
			padding-top: 1rem;
			padding-bottom: 1rem;
			li {
				border-bottom: none;
			}
		}
	}
}

.mobile-nav-back {
	position: relative;
	margin-left: auto;
	background: var(--AvvicWhite);
	width: 90%;
	padding: 0 1em;
}

.mobile-nav.active {
	right: 0;
}

.disable-scroll {
	overflow: hidden;
}
