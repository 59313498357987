@use "../globals";
.pD-wrapper {
	position: relative;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	justify-self: center;
	gap: 2rem;

	h3 {
		color: var(--AvvicDBlue);
	}
	.detail-top {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		gap: 2rem;
		.product-descp-img {
			@media (min-width: 64.1em) {
				margin: 0 auto;
				max-width: 70%;
			}
			img {
				border-radius: var(--borderRadius);
			}
		}
		.product-descp {
			@media (min-width: 64.1em) {
				max-width: 65%;
				margin: 0 auto;
			}
			p {
				@media (min-width: 64.1em) {
					// padding-inline: 15rem;
					text-align: center;
				}
			}
		}
	}

	.content {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: start;
		justify-content: center;
		gap: 2em;

		.features {
			flex-basis: 15em;
			flex-grow: 1;
			padding: 1em 2em;
			display: flex;
			flex-direction: column;
			gap: 0.5em;
			box-shadow: 0.3em 0em 2em rgba(#d2d5d9, 0.3);
			border-radius: 2em;

			span {
				display: flex;
				flex-direction: column;
				gap: 0.6em;
				border-bottom: 0.1em solid rgba(var(--AvvicDGrey), 1);
				li {
					color: var(--textColor);
					list-style-type: disc;
				}
			}
		}
	}
}
