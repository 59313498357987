@use "../globals";
#products-wrapper {
	display: grid;
	gap: 1.5rem;
	border-radius: 5px;
	@media (min-width: 49.75em) {
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 60.06em) {
		// padding: 0rem 8rem;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.product {
		background: var(--AvvicWhite);
		display: flex;
		gap: 0.5rem;
		flex-direction: column;
		padding: 7%;
		border-radius: 25px;
		border: 1px solid var(--Avvic-light-grey);
		h3 {
			color: var(--AvvicDBlue);
		}
		p {
			color: var(--AvvicDBlue);
			font-size: 0.8em;
			flex: 1;
		}
		span {
			width: 100%;
			height: 10em;
			border-radius: 25px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				// box-shadow: 5px 5px 5px gray;
			}
		}
		button {
			width: 100%;
			background-color: transparent;
			background-color: var(--AvvicDCyan);
			border: none;
			color: var(--AvvicWhite);
		}
		button:hover {
			background-color: #afe8e8;
			color: var(--AvvicDBlue);
		}
	}
}
