@use "../globals";

#hero-container {
	background-color: var(--AvvicWhite);
	background-size: cover;
	padding: 3%;
	display: flex;
	flex-direction: column-reverse;

	@media (min-width: 64.06em) {
		display: grid;
		grid-template-columns: 50% 50%;
		justify-content: space-between;
	}

	.hero-text-wrapper {
		display: flex;
		flex-direction: column;
		gap: 1em;

		@media (min-width: 64.06em) {
			padding: 2em;
		}

		p {
			font-size: var(--small-text);

			@media (min-width: 64.06em) {
				width: 60%;
				font-size: var(--text-size);
				color: var(--AvvicDCyan);
			}

			@media (max-width: 786px) {
				text-align: center;
			}
		}

		h1 {
			background: linear-gradient(90deg,
					rgba(2, 11, 50, 1) 0%,
					rgba(13, 153, 153, 1) 55%,
					rgba(18, 204, 204, 1) 84%);
			background-clip: text;
			-webkit-background-clip: text;
			color: transparent;
			-webkit-text-fill-color: transparent;
			text-fill-color: transparent;

			@media (min-width: 49.75em) {
				font-size: var(--larger-text-size);
			}

			@media (max-width: 786px) {
				text-align: center;
			}

			font-weight: var(--headings-font-weight);
		}
	}

	.fade-container {
		width: 100%;
		display: flex;

		.callToActionBtns {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: start;
			gap: 4em;

			.btn {
				width: max-content;
			}

			@media (max-width: 768px) {
				margin: 0 auto;
				justify-content: center;
				gap: 2em;
			}

			button {
				box-shadow: 2px 2px 2px var(--Avvic-light-grey);
				padding: 0.7rem 6em;

				@media (max-width: 786px) {
					padding: 0.7rem 2.4em;
				}
			}

			#get-started-btn {
				width: 100%;
				background-color: var(--AvvicDCyan);
				color: var(--AvvicWhite);
				border: 1px solid var(--AvvicDCyan);

				&:hover{
					background-color: #eafafa;
					color: var(--AvvicDCyan);
					transition: all 0.3s;
				}
			}

			#our-solution-btn {
				width: 100%;
				background-color: transparent;
				border: 1px solid var(--AvvicDCyan);
				color: var(--AvvicDCyan);

				&:hover {

					background-color: #eafafa;
					transition: all 0.3s;
				}
			}
		}
	}

	#hero-img-div {
		max-width: 100%;

		img {
			transform: scale(0);
			opacity: 0;
			transition:
				transform 1s,
				opacity 1s;
		}

		.in-view {
			transform: scale(1);
			opacity: 1;
		}
	}
}