#leadership {
	padding: 0% 5%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	h2 {
		color: var(--AvvicDBlue);
		text-align: center;
		margin-top: var(--whiteSpace);
	}
	#heading-display {
		color: var(--AvvicDCyan);
		text-align: center;
	}
	#btns-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;
		justify-content: center;
		@media (min-width: 49.75em) {
			gap: 2rem;
		}

		button {
			border: 1px solid var(--AvvicDCyan);
			background-color: transparent;
			color: var(--AvvicDCyan);
			font-weight: var(--headings-font-weight);
			transition: 0.3s;
		}
		button:hover {
			background-color: var(--AvvicDCyan);
			color: var(--AvvicWhite);
			transition: 0.3s;
		}
	}

	.leader-wrapper {
		width: 100%;
		display: grid;
		place-items: center;
		grid-template-columns: 1fr;
		place-content: center;
		gap: 2em;
		
		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}

		@media (min-width: 1024px) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		.profile {
			width: max-content;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 1em;
			cursor: pointer;

			h2,
			h4 {
				text-align: center;
			}
			h4 {
				font-size: 1em;
			}
			p {
				font-size: 0.7em;
				text-align: center;
			}

			&:last-child {
				grid-column: span 1;
				width: 100%;
				margin: 0 auto;
				
				@media (min-width: 768px) {
					grid-column: span 2;
				}
	
				@media (min-width: 1024px) {
					grid-column: span 3;
				}
			}
		}

		.fig-img-wrapper {
			position: relative;
			width: 15em;
			height: 15em;
			img {
				width: 100%;
				height: 100%;
				border-radius: 5px;
				border-radius: 50%;
				box-shadow: 5px 5px 5px gray;
			}
		}
		h4 {
			color: var(--AvvicDCyan);
		}
	}
}
