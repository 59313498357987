.why-choose-us-slider {
}

.slick-prev,
.slick-next {
	font-size: 24px;
	color: #0d9999;
	background: #0d9999;
}

.slick-dots li button {
	font-size: 12px;
	background: #0d9999;
}

.slick-dots li.slick-active button {
	background: #0d9999;
	color: #0d9999;
}
