@use "../globals";
.custom-input {
	.custom-label {
		margin: 1px;
	}
	.input-label {
		display: block;
		color: #212121;
		margin-bottom: 1rem;
	}
	input {
		width: 100%;
		padding: 0.7rem;
		border-radius: 8px;
		border: 1px solid var(--Avvic-light-grey);
		margin-top: 0.7rem;
		color: #878e9d;
	}
	.banks-select {
		width: 100%;
		padding: 0.7rem;
		border-radius: 8px;
		border: 1px solid var(--Avvic-light-grey);
		margin-top: 10px;
		text-transform: uppercase;
		color: #878e9d;
	}

	input:focus {
		outline: none !important;
		border: 1px solid var(--Avvic-pry);
		box-shadow: 0 0 10px rgba(45, 45, 45, 0.1);
	}
	input::placeholder {
		color: #999;
		text-transform: capitalize;
	}
}

input[type="radio"] {
	accent-color: var(--Avvic-pry);
}
input[type="checkbox"] {
	accent-color: var(--Avvic-pry);
}
