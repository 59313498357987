.request-history-container {
	grid-column: span 9;
	display: flex;
	justify-content: center;
	scrollbar-width: none;
	overflow: auto;
	height: 100vh;

	.no-request-container {
		display: grid;
		place-content: center;
		height: 100%;
	}
	.request-history-wrapper {
		flex-basis: 90%;
		padding: 2rem;
		.btns-div {
			display: flex;
			gap: 1.5rem;
			button {
				border: none;
				display: flex;
				align-items: center;
				background: transparent;
				padding: 0;
			}
			.filter-btn {
				background-color: #e2f3f3 !important;
				width: 8.5rem;
				padding: 0.5rem;
				justify-content: center;
				gap: 0.3rem;
			}
		}
		.request-history-top {
			display: flex;
			justify-content: space-between;
		}
		.tables-section {
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}
	}
}
.request-history-details-container {
	position: absolute;
	z-index: 2;
	background-color: white;
	top: 0;
	right: -110%;
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	gap: 0.5rem;
	transition: right 0.8s ease-in;
	.requestDetailsTop {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	button {
		padding: 0;
		border: none;
		background-color: transparent;
	}
	p {
		margin: 0;
	}
	.detailValue {
		text-align: start;
	}
	.history-details-wrapper {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		width: 60%;
	}
	.summary {
		display: flex;
		justify-content: space-between;
	}
}
.request-history-details-container.active {
	right: 0;
	transition: right 0.8s ease-in;
}
