@use "globals";
@use "navbar";
@use "landingpage";
@use "footer";
@use "aboutPage";
@use "career";
@use "support";
@use "products";
@use "community";
@use "util";
@use "accountOpening";
@use "onlineportal";

.mySwiper, .mySlider {
	.swiper-button-prev,
	.swiper-button-next {
		color: var(--AvvicCyan);
	}
}