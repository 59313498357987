@use "../globals";

.settings-container {
	grid-column: span 9;
	display: flex;
	flex-direction: column;
	align-content: center;
	padding: 1.8% 4%;
	width: 100%;
	margin-bottom: -2rem;
	overflow-x: hidden;
	position: relative;

	h3 {
		font-size: var(--small-h3-text-size);
		font-weight: bold;
	}
	p {
		font-size: var(--small-p-text-size);
	}
	span {
		color: var(--textColor);
		margin-right: 0.4rem;
	}
	button {
		width: auto;
		height: auto;
		padding: 1.5%;
		border: none;
		border-radius: 100px;
		font-size: var(--btn-text-size);
		cursor: pointer;
	}
	.user-card {
		position: relative;
		display: flex;
		justify-content: flex-start;
		padding: 0 1%;

		.user-image {
			flex: 0 0 auto;
			margin-right: 14px;
			img {
				width: 100px;
				height: 100px;
				border-radius: 50%;
			}
		}

		.edit-button {
			position: absolute;
			top: 4.6rem;
			left: 5rem;

			button {
				background-color: transparent;
				border: none;
				cursor: pointer;

				.edit-icon {
					width: 30px;
					height: 30px;
				}
			}

			input[type="file"] {
				display: none;
			}

			label {
				cursor: pointer;
			}
		}

		.user-info-container {
			flex: 1;
			display: flex;
			align-items: center;
			color: var(--AvvicBlack);
			gap: 0.99rem;
			.user-info {
				.user-name {
					font-size: 1.5rem;
					font-weight: bold;
				}
				.user-email {
					line-height: 36px;
				}
			}
			.logout-button-container {
				.logout-button {
					border: none;
					background-color: var(--AvvicWhite);
				}
			}
		}
	}
	.save-button-wrapper {
		position: absolute;
		top: 2rem;
		right: 3.3rem;
		@media (max-width: 48.9rem) {
			position: static;
			margin-bottom: -3rem;
		}
		.save-button {
			margin-top: 1.5rem;
			width: 170px;
			height: 11%;
			padding: 11.5px 24px;
			background-color: var(--Avvic-pry);
			color: var(--AvvicWhite);
			border: none;
			border-radius: 100px;
			font-size: var(--btn-text-size);
			cursor: pointer;
			&:disabled {
				background-color: #9ad0d0;
				color: var(--AvvicWhite);
				cursor: not-allowed;
			}
		}
	}

	.setting-cards-container {
		display: flex;
		justify-content: space-around;
		margin-top: 1.7rem;

		.card {
			flex-basis: 48.5%;
			padding: 10px;
			border-radius: 12px;
			color: var(--AvvicBlack);
			background-color: var(--Avvic-light-cyan);
			.card-title {
				line-height: 20px;
			}

			.card-content {
				margin-top: 10px;
				color: var(--AvvicBlack);
				.user-name,
				.user-email {
					position: relative;
					padding: 1.5% 0;
					input[type="email"],
					input[type="text"] {
						width: 73%;
						border: 1px solid #ccc;
						padding: 2%;
						border-radius: 5px;
						font-size: 16px;
					}
					button {
						margin-left: 1rem;
						color: var(--Avvic-pry);
						border: 1px solid #0d9999;
					}
					.user-email-edit-btn,
					.username-edit-btn {
						position: absolute;
						right: -1.6%;
						bottom: -22%;
					}
				}
				.user-password {
					position: relative;
					margin-top: -0.8rem;
					span {
						font-size: var(--small-p-text-size);
					}
					.close {
						position: absolute;
						z-index: 99999;
						top: 5%;
						right: 10%;
					}
					.error {
						color: red;
						margin-top: -1.5rem;
					}
					.password-edit-btn {
						position: absolute;
						background-color: var(--Avvic-light-cyan);
						color: var(--Avvic-pry);
						border: 1px solid #0d9999;
						right: -0.5%;
						top: 5%;
						cursor: pointer;
					}
				}
			}
		}
	}

	.other-details-container {
		width: 98.4%;
		padding-top: 2%;
		padding-left: 0.76%;
		padding-right: 0.76%;
		margin-top: 1.5rem;
		margin-left: auto;
		margin-right: auto;
		background-color: var(--Avvic-light-cyan);
		border-radius: 12px;
		color: var(--AvvicBlack);
		.details-title {
			line-height: 2px;
		}
		.account-name,
		.user-account,
		.user-address,
		.user-phone,
		.user-Identification,
		.user-nextOfKin,
		.user-gender,
		.user-birth,
		.user-maritalStatus {
			input[type="tel"],
			input[type="text"] {
				width: 40%;
				border: 1px solid #ccc;
				padding: 1.3%;
				border-radius: 8px;
				font-size: 16px;
			}
			button {
				margin-left: 1rem;
				color: var(--Avvic-pry);
				border: 1px solid #0d9999;
				@media (max-width: 425px) {
					margin-left: 0;
				}
			}
			position: relative;
			padding: 1.1% 0;

			.user-edit-btn {
				position: absolute;
				right: -0.7%;
				bottom: 13%;
			}
		}
		.user-phone {
			.edit-phone-btn {
				position: absolute;
				right: -0.1%;
				bottom: 13%;
			}
		}
		.user-address {
			.uploaded-image {
				display: flex;
				flex-wrap: wrap;
			}

			.file-preview {
				margin: 5px;
			}

			.file-preview img {
				width: 200px;
				height: 200px;
				@media (max-width: 30.9rem) {
					width: 100px;
					height: 100px;
					margin-top: -0.8rem;
					margin-bottom: -0.6rem;
				}
			}
			.edit-address-btn {
				right: 0.1%;
			}
			.addres-input-container {
				margin: 1.2rem 0;
				input[type="text"] {
					width: 98%;
					height: 3rem;
					border: 1px solid #ccc;
					padding: 1.3%;
					margin-left: 0.3rem;
					border-radius: 8px;
					font-size: 16px;
				}
				@media (max-width: 30.9rem) {
					margin-top: -0.3rem;
					input[type="text"] {
						height: 2.5rem;
						margin-bottom: -0.8rem;
					}
				}
			}
		}
	}
}

.settings-modal-container {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	overflow-y: scroll;
	transition: width 0.3s ease-in-out;
	.modal-content {
		position: absolute;
		top: 0;
		right: 0;
		width: 70vw;
		height: 100%;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.password-modal-content {
			margin-top: -10rem;
			height: 68%;
		}
		.close-wrapper {
			.close {
				position: absolute;
				top: 10px;
				right: 2.5rem;
				z-index: 600;
				cursor: pointer;
			}
		}
	}
	@media (max-width: 40.9rem) {
		.password-modal-content,
		.modal-content {
			width: 90%;
			margin-left: auto;
			margin-right: 5%;
		}
		.password-modal-content {
			height: 68%;
		}
		.address-modal-content {
			.address-modal-wrapper {
				height: 12% !important;
			}
		}
		.modal-content {
			margin-top: 15%;
			height: 74%;
			.settings-password-update-wrapper {
				margin-top: 12%;
			}
			.settings-add-phone-wrapper {
				margin-top: -20%;
			}
		}
	}
}
@media (max-width: 768px) and (orientation: landscape) {
	.settings-modal-container {
		height: calc(230vh - 40px);
		.modal-content {
			justify-content: flex-start;
			height: 210vh;
			.settings-password-update-wrapper {
				margin-top: 45%;
			}
		}
		.password-modal-content,
		.address-modal-content {
			justify-content: center !important;
			overflow-y: scroll;
		}
	}
}

// BREAK POINT FOR SMALL DEVICES
@media (max-width: 48.9rem) {
	.settings-container {
		margin-bottom: -0.2rem;
		justify-content: center;
		span {
			margin-right: 0.1rem;
		}
		.user-card {
			position: relative;
			.save-button {
				position: absolute;
				top: 6rem;
				margin-top: 1.5rem;
				width: 60%;
				height: auto;
				padding: 2%;
			}
		}
		.setting-cards-container {
			width: 100%;
			flex-direction: column;
			gap: 2rem;
			margin-top: 4.2rem;
			margin-left: auto;
			margin-right: auto;

			.card {
				padding: 2rem auto;
				.card-content {
					.user-name,
					.user-email {
						input[type="email"],
						input[type="text"] {
							width: 72%;
							border: 1px solid #ccc;
							padding: 2.5%;
							border-radius: 5px;
							font-size: 16px;
						}
						button {
							margin-top: 0.7rem;
							margin-left: 0.2rem;
							padding: 0.4rem;
						}
						.user-email-edit-btn,
						.username-edit-btn {
							position: absolute;
							bottom: -30%;
						}
					}
				}
			}
		}

		.other-details-container {
			padding-top: 2%;
			margin-top: 1.5rem;
			position: relative;
			.details-title {
				line-height: 28px;
			}
			.account-name,
			.user-account,
			.user-address,
			.user-phone,
			.user-Identification,
			.user-nextOfKin,
			.user-gender,
			.user-birth,
			.user-maritalStatus {
				input[type="tel"],
				input[type="text"] {
					width: 76%;
					border: 1px solid #ccc;
					padding: 2.5%;
					border-radius: 8px;
					font-size: 16px;
				}
				button {
					margin-top: 0.7rem;
					margin-left: 0.2rem;
					padding: 0.4rem;
				}
				padding-top: 2%;
				padding-bottom: 2%;
				.user-edit-btn {
					position: absolute;
					right: -0.7%;
					bottom: -29.5%;
				}
			}
			.user-password {
				.form-data {
					width: 80%;
					margin-left: auto !important;
					margin-right: auto !important;
				}

				button {
					position: absolute;
					right: -0.5%;
					top: 5%;
					cursor: pointer;
				}
			}
		}
	}
}
