@use "../globals";
#features {
	display: flex;
	width: 100%;
	background-color: var(--AvvicDCyan);
	flex-wrap: wrap;
	padding: 3%;
	@media (min-width: 49.75em) {
		gap: 3rem;
		flex-wrap: nowrap;
	}

	.feature {
		display: grid;
		justify-content: center;
		gap: 1rem;
		@media (min-width: 49.75em) {
			gap: 1.5rem;
		}
		img {
			max-height: 160px;
		}
		h3,
		p {
			text-align: center;
			color: var(--AvvicWhite);
		}
		div {
			justify-self: center;
		}
	}
}
