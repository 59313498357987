@use "../globals";
#supports-wrapper {
	display: flex;
	flex-direction: column;
	gap: var(--whiteSpace);
	background: linear-gradient(90deg, #fffeff 0%, #d7fffe 100%);
	padding: var(--mobile-page-padding);
	@media (min-width: 49.75em) {
		padding: var(--whiteSpace);
	}
	a:hover {
		color: var(--AvvicDCyan);
		transition: padding 0.3s ease-in-out;
	}

	.support-top {
		text-align: center;
		padding: 2rem 0rem;
		h2 {
			color: var(--AvvicDBlue);
			margin-bottom: 1rem;
		}
	}
	.get-in-touch-wrapper {
		display: flex;
		flex-direction: column-reverse;
		gap: 2rem;
		@media (min-width: 49.75em) {
			display: grid;
			grid-template-columns: 50% 50%;
		}
		@media (min-width: 64.1em) {
			padding-inline: 10%;
		}
		h3 {
			color: var(--AvvicDBlue);
		}
		form {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			input[type="text"],
			input[type="email"],
			input[type="tel"],
			textarea,
			button {
				width: 100%;
			}
			input[type="text"],
			input[type="email"],
			input[type="tel"] {
				padding: 0.7rem;
				border-radius: 8px;
				border: 1px solid var(--Avvic-light-grey);
			}
			textarea {
				border-radius: 8px;
				border: 1px solid var(--Avvic-light-grey);
				resize: none;
			}
			button {
				border: none;
				background-color: var(--AvvicDCyan);
				color: var(--AvvicWhite);
			}
		}
	}
	#getInTouch {
		display: grid;
		gap: 2rem;
		grid-template-columns: repeat(2, 1fr);
		@media (min-width: 64.1em) {
			padding-inline: 10rem;
		}

		.getInTouch-text {
			align-self: center;
		}
		#contact-links-wrapper {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			.contact-links {
				display: grid;
				gap: 2rem;

				@media (min-width: 49.75em) {
					grid-template-columns: 1fr 1fr;
				}
			}
			#get-direction {
				display: flex;
				gap: 1rem;
				text-decoration: none;
			}
		}
	}

	#socials-links-wrapper {
		display: flex;
		gap: 10%;
		margin-top: 1rem;
		max-width: 9.374rem;
		@media (min-width: 64.1em) {
			margin-top: 1rem;
			display: grid;
			gap: 10%;
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
