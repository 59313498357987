@use "../globals";

.fixed-deposit-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.Proposed-duration {
		color: var(--neutral-black, #212121);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
	}

	.deposited-amount {
		color: var(--neutral-black, #212121);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
	}

	.rate {
		margin-right: 37px;
		color: var(--neutral-black, #212121);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
	}

	.fixed-deposit-heading {
		font-size: 32px;
		font-weight: 600;
		line-height: 51px;
		letter-spacing: 0em;
		width: 330px;
		height: 55px;
		color: #212121;
		display: contents;
	}

	.select-account {
		display: flex;
		gap: 10px;
		margin-top: 42px;
		align-items: center;
	}

	.choose-account {
		color: #667085;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 25.6px;
	}

	.bank-select {
		width: 190px;
		height: 40px;
		border-radius: 8px;
		border: 1px solid #d0d5dd;
		background: #fff;
		box-shadow: 0px 1.04009px 2.08017px 0px rgba(16, 24, 40, 0.05);
	}
	.place-info {
		color: #202020;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		margin-top: 42px;
	}
	.duration,
	.amount2 {
		border-radius: 8px;
		border: 1px solid var(--textfield-stroke, #d0d5dd);
		background: var(--White, #fff);
		box-shadow: 0px 1.04009px 2.08017px 0px rgba(16, 24, 40, 0.05);
		padding: 5px;
		width: 400px;
		margin-top: 8px;
		margin-bottom: 8px;
		height: 48px;
	}
	.rate-input {
		border-radius: 8px;
		border: 1px solid var(--textfield-stroke, #d0d5dd);
		background: var(--background-gray, #eff0f1);
		box-shadow: 0px 1.04009px 2.08017px 0px rgba(16, 24, 40, 0.05);
		padding: 5px;
		width: 400px;
		height: 48px;
		margin-bottom: -8px;
		margin-top: 8px;
	}

	.return-heading {
		color: var(--Gray-Text, #667085);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		margin-right: 18px;
		font-weight: 500;
		margin-bottom: 0px;
		line-height: 25.6px;
	}

	.return-choice {
		margin-bottom: 24px;
		margin-top: 18px;
		display: flex;
		flex-direction: column;
		align-items: baseline;
		gap: 12px;
		color: var(--neutral-black, #212121);
	}

	.first-choice,
	.second-choice,
	.third-choice {
		display: flex;
		justify-content: center;
		gap: 8px;
		align-items: center;
	}

	.second-choice {
		// margin-left: 14px;
		// margin-left: 31px;
	}

	.continue-btn {
		border-radius: 100px;
		border: 1px solid var(--Avvic-Blue-Pri, #0d9999);
		background: var(--Avvic-Blue-Pri, #0d9999);
		box-shadow: 0px 0.96021px 1.92041px 0px rgba(16, 24, 40, 0.05);
		padding: 6px;
		width: 400px;
		height: 40px;

		color: var(--White, #fff);
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%;
	}
	.trial {
		margin-top: 24px;
	}
	.content {
		margin-right: 90px;
		font-size: 17px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
