@use "../globals";

.cards-main-container {
	grid-column: span 8;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	padding: 0 5%;
	width: 100%;
	.atm-card {
		display: flex;
		flex-direction: column;
		margin-top: 6rem;
		margin-left: auto;
		margin-right: auto;
		padding: 0 2%;
		width: 30rem;
		color: var(--AvvicBlack);
		.atm-card-images {
			margin: 0 auto;
		}
		.atm-card-text {
			text-align: justify;
		}
		.request-card-button-container {
			width: 100%;
			font-size: 18px;
			margin-top: 0.5rem;
			.request-card-button {
				background-color: var(--Avvic-pry);
				color: var(--AvvicWhite);
				border: none;
				padding: 0.8rem;
				width: 100%;
			}
		}
	}

	.atm-card-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 6rem;
		margin-left: auto;
		margin-right: auto;
		width: 30rem;
		h3 {
			text-align: center;
			color: var(--AvvicBlack);
			margin-bottom: 3rem;
			font-weight: 700;
		}
		.atm-select-wrapper {
			.atm-account-select-container {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				margin-bottom: 1.4rem;
				.atm-account-select {
					width: 12rem;
					height: 3rem;
					border-radius: 8px;
					cursor: pointer;
					padding: 1%;
				}
			}
			.atm-card-select-container {
				line-height: 32px;
				margin-bottom: 1.5rem;
				margin-left: auto;
				margin-right: auto;
				color: var(--AvvicBlack);
				.atm-account-select {
					width: 30rem;
					height: 2.5rem;
					border-radius: 8px;
					cursor: pointer;
					padding: 1%;
				}
			}
		}
		p {
			text-align: center;
			color: var(--AvvicBlack);
		}
		.atm-btn-container {
			display: flex;
			justify-content: center;
			gap: 1.5rem;
			margin-top: 1.5rem;
			.atm-submit-btn,
			.atm-pervious-btn {
				padding: 2.5%;
				width: 14rem;
			}
			.atm-pervious-btn {
				background-color: var(--AvvicWhite);
				color: var(--Avvic-pry);
				border: 1px solid var(--Avvic-pry);
			}

			.atm-submit-btn {
				background-color: var(--Avvic-pry);
				color: var(--AvvicWhite);
				border: none;
			}
		}
	}

	//////////////////////////
	.atm-status-wrapper {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
		width: 38rem;
		.disabled-card-overlay {
			width: 100%;
			height: 100%;
			.disabled-card {
				width: 541px;
				height: 186px;
				padding: 32px;
				display: flex;
				flex-direction: column;

				@media screen and (max-width: 767px) {
					width: 93%;
				}
				.disabled-card-content {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-align: center;
				}
				p {
					font-size: 24px;
					margin: 16px 0;
					color: var(--AvvicBlack);

					@media screen and (max-width: 767px) {
						font-size: 16px;
						margin-top: 12px;
						margin-bottom: 23px;
					}
				}
				.btn-container {
					display: flex;
					flex-direction: row;
					gap: 1.5rem;
					@media screen and (max-width: 767px) {
						gap: 2rem;
						margin: 0 auto;
					}
					.btn-continue,
					.btn-cancel {
						width: 224px;
						height: 52px;
						padding: 10px 16px;
						font-size: 16px;
						cursor: pointer;
						@media screen and (max-width: 767px) {
							width: 132px;
							height: 45px;
							padding: 2% 5%;
						}
					}
					.btn-continue {
						background-color: var(--Avvic-pry);
						color: #fff;
						border: none;
					}

					.btn-cancel {
						background-color: var(--AvvicWhite);
						color: #000;
						border: 1px solid var(--Avvic-light-grey);
					}
				}
			}
			.overlay {
				width: 100%;
				height: 100%;
			}
		}

		.user-atm-card-container,
		.request-heading-container,
		.request-card-container,
		.recent-card-container {
			display: flex;
			width: auto;
		}
		.user-atm-card-container {
			border-radius: 12px;
			gap: 2rem;
			align-items: center;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
			margin-bottom: 3rem;
			.user-atm-card {
				img {
					width: 250px;
					height: 180px;
				}
				.disable-card-btn {
					width: 100%;
					padding: 2%;
					border-radius: 100px;
					margin-top: 3%;
					color: var(--Avvic-pry);
					border: 1px solid var(--Avvic-pry);
					background-color: var(--AvvicWhite);
				}
			}
			.user-atm-card-content {
				flex-grow: 0;
				p {
					color: var(--AvvicBlack);
					font-size: 16px;
					span {
						font-size: 14px;
						color: var(--Avvic-grey);
						margin-right: 2%;
					}
				}
				.card-number-content,
				.card-cvv-content {
					position: relative;
					img {
						position: absolute;
						margin-left: 3%;
					}
				}
			}
		}
		.request-heading-container {
			gap: 2.5%;
			font-size: 24px;
			justify-content: center;
			color: var(--Avvic-deep-yellow);
			margin-bottom: 2%;
		}
		.request-card-container {
			background-color: var(--Avvic-grey);
			gap: 7%;
			padding: 2%;
			border-radius: 12px;
			justify-content: center;
			align-items: center;
			line-height: 28.8px;
			font-size: 15px;
			margin-bottom: 3rem;
			.request-card-child:nth-child(2) {
				button {
					width: 18rem;
					background-color: var(--Avvic-pry);
					color: var(--AvvicWhite);
					border-radius: 100px;
					border: none;
				}
			}
		}
		.recent-card-container {
			position: relative;
			justify-content: baseline;
			margin-bottom: 0.5rem;
			gap: 5%;
			.recent-card-child:nth-child(2) {
				position: absolute;
				left: 84%;
				top: -12%;
			}
			.recent-card-child:nth-child(3) {
				img {
					position: absolute;
					left: 96%;
				}
			}
		}
		.table-container {
			display: flex;
			justify-content: baseline;
			width: auto;
			.table-child {
				flex: 1;
				width: 10rem;
				border: 1px solid var(--Avvic-grey);
				padding: 1.5%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			.table-child:first-child {
				border-top-left-radius: 12px;
				border-bottom-left-radius: 12px;
			}
			.table-child:nth-child(4) {
				border-top-right-radius: 12px;
				border-bottom-right-radius: 12px;
			}
			.table-child:nth-child(3) {
				position: relative;
				text-align: center;
				img {
					position: absolute;
					left: 3%;
					border: none;
				}
				span {
					margin-left: 9%;
				}
			}
		}
	}
}

// MEDIA QUERY (BREAK POINTS)

@media (max-width: 30rem) {
	.atm-card {
		width: 93%;
	}
	.atm-card-wrapper {
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		padding: 0 5%;
		.atm-account-select-container {
			flex-direction: column;
			justify-content: center;
		}
		.atm-card-select-container {
			justify-content: center;
			.atm-account-select {
				width: 100%;
			}
		}
	}
}

@media (min-width: 31rem) and (max-width: 48.9rem) {
	.atm-card {
		width: 80%;
	}
	.atm-card-wrapper {
		width: 90%;
		justify-content: center;
		.atm-account-select-container,
		.atm-card-select-container {
			flex-direction: column;
			justify-content: center;
		}
		.atm-card-select-container {
			.atm-account-select {
				width: 90%;
			}
		}
	}
}

@media (min-width: 64rem) and (max-width: 75rem) {
	.atm-card {
		margin-top: 11rem;
		margin-left: 25rem;
	}
	.atm-card-wrapper {
		margin-left: 7.5rem !important;
	}
}

@media (min-width: 76rem) {
	.atm-card {
		margin-top: 11rem;
	}
}
