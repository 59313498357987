@use "../globals";
.drag-and-drop-container {
	.file-to-upload-list-wrapper {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		li {
			list-style: disc;
		}
	}
	.drag-and-drop {
		border: 1px dashed var(--AvvicVDGrey);
		padding: 3%;
		word-break: break-all;
		border-radius: 8px;
		text-align: center;
		.drag-and-drop-change-btn-div {
			display: flex;
			justify-content: end;
			.dag-btn {
				border: none;
				font-size: 0.65rem;
				background-color: transparent !important;
				color: red !important;
			}
		}

		.blue {
			color: blue;
			cursor: pointer;
		}
	}
	.drag-and-drop.error {
		border: 1px dashed var(--Avvic-red);
		p,
		li {
			color: var(--textColor);
			font-size: 0.9rem;
		}
	}
	.file-preview-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		padding: 0.5rem;
		.file-preview {
			max-width: 150px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			span {
				font-size: 0.8em;
			}
		}
	}
}
