@use "../globals";

.overlay {
	.modal-wrapper {
		.career-heading {
			text-align: center;
		}
	}
	.job-form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 75%;
		margin-inline: auto;
		gap: 1rem;
		label {
			span {
				color: var(--Avvic-red);
			}
		}
		input {
			background-color: transparent;
			border: 1px solid var(--textColor);
			font-size: var(--text-font);
			border-radius: 5px;
			line-height: 1.7;
			width: 100%;
			padding: 1%;
		}
		input:focus {
			outline: none !important;
			border: 1px solid var(--Avvic-pry);
			box-shadow: 0 0 10px rgba(45, 45, 45, 0.1);
		}
		.custom-select {
			position: relative;
			select {
				appearance: none;
				outline: none;
				background-color: transparent;
				border: 1px solid var(--textColor);
				font-size: var(--text-font);
				border-radius: 5px;
				line-height: 1.7;
				width: 100%;
				padding: 1%;
				color: var(--text-font);
			}
			.icon-wrapper {
				position: absolute;
				right: 1rem;
				top: 0.8rem;
			}
		}
		select:focus {
			border-color: var(--Avvic-pry) !important;
			box-shadow: 0 0 10px rgba(45, 45, 45, 0.1);
		}
		.modal-submit-btn {
			background-color: var(--AvvicDCyan);
			border: none;
			color: var(--AvvicWhite);
			padding: 1rem;
			margin-bottom: 5%;
		}
	}
}
