:root {
	--normal-btn-width: 8.5rem;
}

html {
	box-sizing: border-box;
	font-size: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
	width: var(--WkWidth);
	height: var(--WKHeight);
	background-color: var(--Bg-AvvicOffWhite);
	position: relative;
}

li {
	list-style: none;
}

img {
	max-width: 100%;
}

button {
	border-radius: 25px;
	padding: 0.7em;
	cursor: pointer;
}

.App {
	display: flex;
	flex-direction: column;
	max-width: 1920px;
	margin: 0 auto;
}

.swiper-container {
	position: relative;
	width: 100%;

	.swiper-img {
		width: 3em;
		height: 3em;
		border-radius: 0.6em;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			border-radius: 0.6em;
		}
	}
}