@use "../globals";

.fqa-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (min-width: 64.1em) {
		max-width: 70%;
		margin: 0 auto;
	}
	h3 {
		color: var(--AvvicDBlue);
	}
	.rotate-chevron {
		transform: rotate(180deg);
		transition: rotate 0.3s;
	}
	ul,
	li {
		margin: 0;
		padding: 0;
		p {
			color: var(--AvvicDBlue);
			margin: 0.5rem;
		}
	}

	.fqa-btn {
		border: none;
		display: flex;
		gap: 3rem;
		width: 100%;
		justify-content: space-between;
		background: transparent;
		border-radius: 0;
		border-bottom: 1px solid var(--AvvicDBlue);
		padding-inline: 3%;
		text-align: start;
		span {
			max-width: 10px;
		}
	}
	.fqa-ans {
		font-size: 0.8rem;
		padding: 0.5rem;
		text-align: start;
	}
	.showAns {
		max-height: 62.5rem;
		transition: max-height 0.4s ease-in;
		overflow-y: auto;
	}
	.hideAns {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.4s ease-out;
	}
}
