@use "../globals";

#dropDown {
	padding-inline: 5%;
	ul {
		padding: 0;
		margin: 0;
		color: var(--textColor) !important;
	}
	.rotate-chevron {
		transform: rotate(180deg);
		transition: rotate 0.3s;
	}
	li {
		margin: 0;
		padding: 1rem 0rem;
		@media (min-width: 49.75em) {
			padding: 1% 0%;
		}
	}
	.jobCategory-li {
		cursor: pointer;
	}
	.jobCategory-btn {
		border: none;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid var(--AvvicDBlue);
		border-radius: 0;
		width: 100%;
		background-color: transparent;
	}
	h4 {
		color: var(--AvvicDBlue) !important;
		margin: 0;
	}
	span {
		max-width: 10px;
	}

	.showDropDown > .jobs-dropD {
		max-height: 62.5rem;
		transition: max-height 0.4s ease-in;
		overflow-y: auto;
	}
	.hideDropDown > .jobs-dropD {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.4s ease-out;
	}
	.jobs-dropD {
		h5 {
			color: var(--AvvicDCyan);
		}
		.location-p {
			color: var(--AvvicDBlue);
			font-weight: 600;
		}
		p {
			@media (min-width: 49.75em) {
			}
		}
		.job {
			padding: 2% 4%;
		}

		.dropD-text {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			margin-bottom: 1%;
		}
		.apply-btn {
			border: none;
			background-color: var(--AvvicDCyan);
			color: var(--AvvicWhite);
			@media (min-width: 49.75em) {
			}
		}
	}
}
