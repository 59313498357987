@use "../globals";
#socialProof {
	background-image: url("../../../public/assets/[Downloader.la]-645278f9b6260-min.jpg");
	color: var(--AvvicWhite);
	display: flex;
	flex-direction: column;
	// gap: 1em;
	padding: 3%;
	@media (min-width: 49.75em) {
		padding: 3rem;
		// margin-top: calc(var(--whiteSpace) + 1rem);
	}

	#why-avvic {
		color: var(--AvvicDCyan);
		font-weight: var(--headings-font-weight);
	}
	.SP-text {
		display: grid;
		gap: 3rem;
		@media (min-width: 49.75em) {
			grid-template-columns: 60% 40%;
		}

		h2 {
			font-weight: var(--headings-font-weight);
		}
		h3 {
			font-weight: var(--headings-font-weight);
			color: var(--AvvicDCyan);
		}
		.social-proof-grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 1rem;
		}
	}

	.btn-div {
		display: flex;
		justify-content: center;
		width: 100%;
		#find-out-btn {
			border: 2px solid var(--AvvicWhite);
			background-color: transparent;
			color: var(--AvvicWhite);
			width: var(--normal-btn-width);
		}
	}
}
