@use "../globals";

.edit-button-icon-wrapper {
	button {
		background-color: transparent;
		border: none !important;
		cursor: pointer;

		.edit-icon {
			width: 30px;
			height: 30px;
		}
	}

	label {
		cursor: pointer;
	}
}
