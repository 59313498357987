.stepper-wrapper {
	margin-bottom: 3%;
	.stepper-list {
		width: 100%;
		display: flex;
		justify-content: space-between;
		list-style: none;
		margin: 0 auto;
		padding: 0%;
		@media (min-width: 49.75em) {
			gap: 1rem;
			justify-content: center;
		}
	}
	.stepper-list-item {
		font-size: 0.8rem;
		transition: all 0.4s;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	ul li .progress.active {
		background-color: var(--Avvic-pry);
	}
	ul li .progress {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: var(--very-light-grey);
		margin: 14px 0;
		display: grid;
		place-items: center;
		color: #fff;
		position: relative;
		cursor: pointer;
	}
	ul li:last-child .progress::before {
		display: none;
	}
	.progress::before {
		content: " ";
		position: absolute;
		height: 3px;
		background-color: var(--Avvic-grey);
		left: 40px;
		width: calc(100vw / 8);
		@media (min-width: 49.75em) {
			width: calc(100vw / 12);
		}
		transition: width 0.4s ease-out;
	}
	ul li:last-child .progress::after {
		display: none;
	}
	.progress::after {
		content: " ";
		position: absolute;
		width: 0;
		height: 3px;
		background-color: var(--AvvicCyan);
		left: 40px;
		transition: width 0.4s ease-out;
	}
	.progress.active::after {
		left: 30px;
		width: 400%;
		width: calc(100vw / 8);
		@media (min-width: 49.75em) {
			width: calc(100vw / 10);
		}
	}
	.progress.active::before {
		background-color: transparent;
	}
	.stepper-title {
		display: none;
		@media (min-width: 49.75em) {
			display: block;
			margin: 0;
			padding: 0;
			text-align: center;
		}
	}
	.stepper-title.active {
		display: none;
		@media (min-width: 49.75em) {
			display: block;
			width: 50%;
			color: var(--AvvicCyan);
			transition: all 0.4s;
		}
	}

	.stepper-list-item {
		font-size: 0.8rem;
		transition: all 0.4s;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	ul li .progress.active {
		background-color: var(--Avvic-pry);
	}
	ul li .progress {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: var(--very-light-grey);
		margin: 14px 0;
		display: grid;
		place-items: center;
		color: #fff;
		position: relative;
		cursor: pointer;
	}
	ul li:last-child .progress::before {
		display: none;
	}
	.progress::before {
		content: " ";
		position: absolute;
		height: 3px;
		background-color: var(--Avvic-grey);
		left: 40px;
		width: 100vw;
		width: calc(100vw / 8);
		@media (min-width: 49.75em) {
			width: calc(100vw / 12);
		}
		transition: width 0.4s ease-out;
	}
	ul li:last-child .progress::after {
		display: none;
	}
	.progress::after {
		content: " ";
		position: absolute;
		width: 0;
		height: 3px;
		background-color: var(--AvvicCyan);
		left: 40px;
		transition: width 0.4s ease-out;
	}
	.progress.active::after {
		width: calc(100vw / 8);
		@media (min-width: 49.75em) {
			width: calc(100vw / 10);
		}
	}
	.progress.active::before {
		background-color: transparent;
	}
	.stepper-title {
		display: none;
		@media (min-width: 49.75em) {
			display: block;
			margin: 0;
			padding: 0;
			text-align: center;
		}
	}
	.stepper-title.active {
		display: none;
		@media (min-width: 49.75em) {
			display: block;
			color: var(--AvvicCyan);
			transition: all 0.4s;
		}
	}
}
