@use "../globals";
#community-wrapper {
	background: url("../../../public/assets/Rectangle\ 34.png");
	justify-content: center;
	color: var(--AvvicWhite);
	text-align: center;
	gap: 2rem;
	padding: 2rem;
	img {
		border-radius: 5px;
	}
	aside {
		align-self: center;

		button {
			background-color: var(--AvvicDCyan);
			color: var(--AvvicWhite);
			font-size: var(--btn-text-size);
			border: 2px solid var(--AvvicDCyan);
			width: var(--normal-btn-width);
		}
	}
}
