.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background-color: var(--AvvicWhite);
}

.dot {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: var(--AvvicDCyan);
	margin: 0 4px;
	animation: bounce 2s infinite;
}

.dot:nth-child(1) {
	animation-delay: 0.2s;
}

.dot:nth-child(2) {
	animation-delay: 0.6s;
}

.dot:nth-child(3) {
	animation-delay: 1s;
}

@keyframes bounce {
	0%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-12px);
	}
}
