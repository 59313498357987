@use "../globals";
.portal {
	background: url("../../../public/assets/portal-bg.png") center;
	background-size: cover;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.card-wrapper {
		background-color: var(--AvvicWhite);
		border-radius: 20px;
		padding: 3%;
		display: flex;
		flex-direction: column;
		gap: 2rem;

		width: 90%;
		@media (min-width: 49.75em) {
			width: 60%;
		}
		@media (min-width: 64.1em) {
			width: 30%;
		}
		h3,
		p {
			padding: 0;
			margin: 0;
		}
		.logo-wrapper {
			display: flex;
			justify-content: center;
			.logo-text {
				color: var(--Avvic-pry);
				font-size: 1.4rem;
			}
		}
		form {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			input[type="text"],
			input[type="password"] {
				width: 100%;
				padding: 0.7rem;
				border-radius: 8px;
				border: 1px solid var(--Avvic-light-grey);
			}
			.password-wrapper {
				position: relative;
				.eye-btn {
					position: absolute;
					border: none;
					right: 1rem;
					padding: 0;
					margin: 0;
					background-color: transparent;
					top: 0.5rem;
				}
			}
			.two-cols {
				display: flex;

				justify-content: space-between;
			}
		}
		.card-p {
			display: flex;
			justify-content: center;
			gap: 0.5rem;
		}
		.card-btn {
			width: 100%;
			border: none;
			background-color: var(--AvvicDCyan);
			color: var(--AvvicWhite);
		}
	}
	.blue {
		color: #696cff;
		text-decoration: underline;
		a {
			color: #696cff;
		}
	}
}
