@use "../globals";

.fixed-investment {
	grid-column: span 9;
	.back-btn {
		cursor: pointer;
		margin: 1em 0;
		width: 30px;
	}
}
