// D means dark
// V means very
:root {
	--AvvicDBlue: #020b32;
	--AvvicCyan: #12cccc;
	--Avvic-pry: #0d9999;
	--textColor: #626364;
	--Bg-AvvicOffWhite: #f5f7f9;
	--AvvicWhite: #ffffff;
	--AvvicDCyan: #0d9999;
	--AvvicVDGrey: #353c5b;
	--Avvic-light-grey: #d2d5d9;
	--very-light-grey: #f9fafb;
	--Avvic-grey: #d0d5dd;
	--AvvicBlack: #000000;
	--AvvicPurple: #f2f7fd;
	--AvvicGreen: #e0ffe0;
	--Avvic-red: #fa4b4b;
	--Avvic-hover-purple: #5a2995;
	--Avvic-deep-yellow: #ef6c00;
	--Avvic-light-cyan: #0d999914;
}
body {
	color: var(--textColor);
	background-color: var(--AvvicWhite);
}
a {
	color: var(--textColor);
}
