@use "../globals";
.instant-account-container {
	background-color: var(--AvvicWhite);
	@media (min-width: 60.1em) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.account-form-hero-wrapper {
		position: relative;
		min-height: 100vh;
		.form-img-overlay {
			position: absolute;
			padding-left: 4rem;
			height: 100%;
			width: 100%;
			background: linear-gradient(180deg, #121d1d 0%, rgba(9, 15, 15, 0) 100%);
		}
		background-image: url("../../../public/assets/Rectangle\ 36.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		#account-form-hero {
			display: none;
			@media (min-width: 60.1em) {
				display: block;
				height: 100%;
			}
		}
	}
	.account-form-hero-wrapper {
		display: none;
		@media (min-width: 60.1em) {
			display: block;
			height: 100%;
		}
	}
	.account-form-hero-wrapper.form-completed {
		display: none;
	}
	#account-form-hero {
		display: none;
		@media (min-width: 60.1em) {
			display: block;
			height: 100%;
		}
	}
}
.account-form-hero-wrapper {
	display: none;
	@media (min-width: 60.1em) {
		display: block;
		height: 100%;
	}
}
.account-form-hero-wrapper.form-completed {
	display: none;
}
h3 {
	color: var(--AvvicDBlue);
}
.form-headings {
	margin-bottom: 1rem;
}
.radio-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1rem;
	margin-bottom: 1rem;
}
.accountCategory {
	background-color: #0d99991f;
	padding: 1rem;
	margin-bottom: 1rem;
	h4 {
		text-align: center;
		margin-bottom: 0.5rem;
	}
	border-radius: 8px;
	.radio-input {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		justify-content: center;
	}
}
.forms-wrapper {
	padding-inline: 1rem;
	max-height: 100vh;
	overflow: auto;
	@media (min-width: 60.1em) {
		padding-inline: 2rem;
	}
	h3 {
		margin-bottom: 1rem;
	}
	.success-msg-wrapper {
		h2,
		p {
			text-align: center;
		}
		h2 {
			color: var(--AvvicDCyan);
		}
	}
	.go-home-btn-wrapper {
		display: flex;
		justify-content: center;
		#go-home-btn {
			border: none;
			background-color: var(--AvvicDCyan);
			color: var(--AvvicWhite);
			padding: 0.7rem;
			width: 100%;
			@media (min-width: 49.75em) {
				width: 25rem;
			}
		}
	}
	.registrationComplete-wrapper {
		background-color: var(--AvvicWhite);
		border-radius: 20px;
		padding: 2rem;
	}
	.registrationComplete-img-wrapper {
		display: flex;
		width: 50%;
		margin: 0 auto;
		justify-content: center;
		#registrationComplete-img {
			animation: zoomInFromNothing 1s both;
		}
	}
}
.instant-account-container.form-completed {
	display: block;
	min-height: 100vh;
	@media (min-width: 60.1em) {
		margin: 0 auto;
		display: block;
	}
	#account-form-hero {
		@media (min-width: 60.1em) {
			display: none;
			height: 100%;
		}
	}
}
