@use "../globals";
#services {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 3%;
	@media (min-width: 49.75em) {
		gap: 5rem;
	}
	@media (min-width: 64.1em) {
		padding-inline: 10%;
	}
	.vertical {
		display: none;
		@media (min-width: 49.75em) {
			width: 1.5px;
			height: 13rem;
			min-height: 90%;
			display: inline-block;
			background-color: var(--AvvicDBlue);
			justify-self: center;
			align-self: flex-start;
		}
	}
	#bottom {
		display: inline-block;
		background-color: var(--AvvicDBlue);
		justify-self: center;
		align-self: flex-end;
	}
	p {
		margin: 0;
	}
	h2 {
		text-align: center;
		color: var(--AvvicDBlue);
		font-weight: var(--headings-font-weight);
	}
	h3 {
		color: var(--AvvicDBlue);
		font-weight: var(--headings-font-weight);
		margin-bottom: 1rem;
	}
	#services-wrapper {
		display: grid;
		@media (min-width: 49.75em) {
			grid-template-columns: 49% 2% 49%;
		}
		.service {
			display: grid;
			padding: 1.5rem;
			@media (min-width: 49.75em) {
				grid-template-columns: repeat(9, 1fr);
			}
		}
		.service-text {
			grid-column: span 8;
			@media (min-width: 49.75em) {
				padding: 4%;
			}
		}

		.horizontal {
			border-bottom: 1.5px solid var(--AvvicDBlue);
		}
		.mobile-border {
			border-bottom: 1.5px solid var(--AvvicDBlue);
			@media (min-width: 49.75em) {
				border-bottom: none;
			}
		}
	}
}
