@use "../globals";
.mfb-dashboard {
	.side-nav-wrapper {
		padding: 1rem;
		box-shadow: 0 0 10px rgba(45, 45, 45, 0.1);
		grid-column: span 3;
		max-width: 350px;
		.logo-wrapper {
			padding: 1rem 0rem;
			.logo-text {
				color: var(--Avvic-pry);
				font-size: 1.5em;
				font-weight: 500;
				padding: 1rem 0rem;
			}
		}
		.user-details {
			display: flex;
			gap: 0.5rem;
			margin: 2rem 0rem;
		}
		.user-email {
			word-wrap: break-word;
			white-space: pre-wrap;
			word-break: break-word;
		}
		ul {
			display: flex;
			flex-direction: column;
			gap: 0.8rem;
			padding: 0;
			a {
				text-decoration: none;
				font-size: 0.85em;
			}
			li {
				display: flex;
				gap: 0.5rem;
				padding: 0.8rem;
				align-items: center;
				transition: all 0.3s ease-in-out;
				&.active-path {
					border-right: 4px solid #020b32;
					background-color: var(--Avvic-pry);
					border-radius: 5px;
					color: var(--AvvicWhite);
				}
				&:hover {
					border-right: 4px solid #020b32;
					background-color: var(--Avvic-pry);
					border-radius: 5px;
					color: var(--AvvicWhite);
				}
			}
		}
	}
}
