@use "../globals";
.custom-select,
.select-error {
	position: relative;
	select {
		appearance: none;
		outline: none;
		background-color: transparent;
		border: 1px solid var(--Avvic-light-grey);
		font-size: 0.8rem;
		border-radius: 8px;
		line-height: 1.7;
		width: 100%;
		padding: 0.5rem;
		color: var(--textColor);
		margin-top: 0.7rem;
	}
	.icon-wrapper {
		position: absolute;
		right: 1rem;
		top: 2.5rem;
	}
	option {
		color: var(--textColor);
	}
}
.custom-select {
	select {
		border: 1px solid var(--Avvic-light-grey);
		transition: all 0.3s ease-in-out;
	}
}
.select-error {
	select {
		border: 1px solid var(--Avvic-red);
		transition: all 0.3s ease-in-out;
	}
}
select:focus {
	border-color: var(--Avvic-pry) !important;
	box-shadow: 0 0 10px rgba(45, 45, 45, 0.1);
}
