@use "../globals";
.accounts-forms {
	display: flex;
	gap: 1rem;
	flex-direction: column;
	grid-auto-rows: 4rem;
	gap: 1rem;
}

.accounts-btn {
	width: 100%;
	border: none;
	background-color: var(--AvvicDCyan);
	color: var(--AvvicWhite);
	padding: 0.7rem;
}
.accounts-btn.prev-btn {
	background-color: var(--AvvicWhite);
	border: 1px solid var(--Avvic-pry);
	color: var(--Avvic-pry);
}
.no-error {
	max-height: 0;
}
.input-error {
	border: 0.5px solid var(--Avvic-red);
	outline: 0.5px solid var(--Avvic-red);
}
.error {
	color: var(--Avvic-red);
	font-size: 0.7rem;
}
.two-btn-div {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
}
.form-number {
	background-color: black;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	color: var(--AvvicWhite);
	display: flex;
	align-items: center;
	justify-content: center;
}
.add-sig-btn {
	background-color: transparent;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	border: 2px solid var(--Avvic-pry);
	color: var(--Avvic-pry);
}

.referenceReq {
	color: var(--Avvic-red);
}

.sample {
	width: max-content;
	border: 0.02em solid var(--AvvicDCyan);
	border-radius: 0.5em;
	background: transparent;
	color: var(--Avvic-pry);

	&:hover {
		background: var(--Avvic-pry);
		color: var(--AvvicWhite);
	}
}

.two-input-div {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 4rem;
	gap: 1rem;
	margin-bottom: 1rem;

	@media (min-width: 49.75em) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
	}
}
.multiple-input-div {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 0.5rem 0rem;
	input {
		width: auto;
	}
}
