@use "../globals";
#accounts-wrapper {
	background-color: var(--AvvicWhite);

	#accounts-intro-wrapper {
		display: flex;
		flex-direction: column-reverse;
		color: var(--AvvicWhite);
		background-color: var(--AvvicDCyan);
		@media (min-width: 49.75em) {
			display: grid;
			grid-template-columns: 50% 50%;
		}
		aside {
			align-self: center;
			padding: 3%;
			text-align: center;
			@media (min-width: 49.75em) {
				padding-inline: 10%;
				text-align: start;
			}
			.accounts-cta-div {
				button {
					background-color: var(--AvvicWhite);
					border: none;
					color: var(--AvvicDCyan);
					width: 50%;
					padding: 0.7rem;
				}
				button:hover {
					background-color: #eafafa;
					transition: all 0.3s;
				}
			}
			button:hover {
				background-color: #eafafa;
				transition: all 0.3s;
			}
		}
	}
	.accounts-features {
		display: flex;
		flex-direction: column;
		padding: 3%;
		gap: 2rem;
		justify-content: center;
		@media (min-width: 49.75em) {
			flex-direction: row;
		}
		h3 {
			color: var(--AvvicDCyan);
		}
		.feature {
			border: 1px solid var(--Avvic-light-grey);
			padding: 3%;
		}
	}
	#requirements-wrapper {
		background-color: var(--AvvicDCyan);
		color: var(--AvvicWhite);
		padding: 5% 3%;
		position: relative;

		ol {
			li {
				list-style: decimal;
				line-height: 1.7;
			}
		}
	}
}
