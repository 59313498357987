@use "../globals";
.sideNav-container.active {
	width: 18rem;
	padding: 2%;
	.sidenav-open.active {
		transition: 0.3s;
		ul {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			justify-content: center;
			padding: 0;
			margin: 0;
			cursor: pointer;
			li {
				display: flex;
				width: 16rem;
				gap: 1rem;
			}
		}
	}
}

.sideNav-container {
	position: static;
	width: 4rem;
	border-top: 1px solid var(--Avvic-light-grey);
	border-right: 1px solid var(--Avvic-light-grey);
	// transition: all 0.3s;

	@media (min-width: 60em) {
		width: 6rem;
	}
	a {
		text-decoration: none;
	}
	h4 {
		color: var(--AvvicDCyan);
	}
	.nav-upper {
		display: none;
		@media (min-width: 60em) {
			display: flex;
			padding: 2%;
			justify-content: space-between;
		}
		img {
			max-width: 110px;
			@media (min-width: 60em) {
				margin-top: 2%;
				max-width: 110px;
			}
		}
	}

	.close-btn {
		background-color: transparent;
		border: none;
	}
	.bar {
		display: block;
		width: 15px;
		height: 2px;
		margin: 5px auto;
		-webkit-transition: all 0.3s ease-in-out;
		// transition: all 0.3s ease-in-out;
		background-color: var(--AvvicDCyan);
		border-radius: 2px;
	}
	.hamburger {
		display: none;
		@media (min-width: 64.1em) {
			display: block;
			background: transparent;
			border: none;
			padding: 0.5rem;
			margin-left: 0.5rem;
			// margin-left: 1rem;
		}
	}
	.active > .hamburger {
		border: 2px solid var(--AvvicDCyan);
	}
	.active > .bar:nth-child(1) {
		transform: translateY(4px) rotate(45deg);
	}
	.active > .bar:nth-child(2) {
		transform: translateY(-3px) rotate(-45deg);
	}
	.active > .bar:nth-child(3) {
		opacity: 0;
	}
	.sidenav-open {
		// transition: 0.3s;
		ul {
			display: flex;
			flex-direction: column;
			gap: 3rem;
			justify-content: center;
			padding: 0;
			cursor: pointer;

			li {
				padding: 1rem;
				display: flex;
				margin: 0;
				img {
					max-width: 6.25rem;
				}
				p {
					margin: 0;
					padding: 0;
				}
			}
			li:hover {
				background-color: #d4f2f2;
				// transition: all 0.3s;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			li.activePath {
				border-left: 4px solid var(--AvvicDCyan);
				background-color: #d4f2f2;
				// transition: all 0.3s;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}
	}
}
