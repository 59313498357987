@use "../globals";
.table-warpper {
	.table-date {
		margin-bottom: 1rem;
		margin-top: 1rem;
	}
	.history-table {
		width: 100%;
		border-top: 1px solid var(--Avvic-grey);
		border: 1px solid var(--Avvic-grey);
		border-radius: 10px;
		tr {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			border-bottom: 1px solid var(--Avvic-grey);
			padding: 0;
			margin: 0;
			cursor: pointer;
		}
		tr:last-child {
			border-bottom: none;
		}
		td {
			padding: 0.7rem;
			border-right: 1px solid var(--Avvic-grey);
			display: flex;
			align-items: center;
			justify-content: center;
		}
		td:last-child {
			border-right: none;
		}
		td,
		tr {
			.status {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				padding: 0.4rem 0.7rem;
				width: fit-content;
				border-radius: 24px;
			}
			.status.approved {
				color: #21981f;
				background-color: rgba(33, 152, 31, 0.1);
			}
			.status.pending {
				color: #f9a825;
				background-color: rgba(249, 168, 37, 0.2);
			}
			.status.failed {
				color: #cf3434;
				background-color: rgba(207, 52, 52, 0.1);
			}
		}
	}
}
.table-warpper:last-child {
	margin-bottom: 3rem;
}
