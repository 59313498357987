@use "../globals";

.otp-modal-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);

	.card {
		border-radius: 20px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		padding: 4% 5.6% 4% 5.6%;
		text-align: center;
		width: 50%;
		background-color: var(--AvvicWhite);

		h2 {
			margin-bottom: 20px;
			font-weight: 700;
			font-size: var(--small-h2-text-size);
			color: var(--AvvicBlack);
		}
		p {
			font-size: 1.2em;
			color: var(--AvvicBlack);
		}
	}

	.otp-container {
		display: flex;
		justify-content: center;
		gap: 0.9rem;

		.otp-input {
			border: 1px solid #ccc;
			border-radius: 8px;
			padding: 0.625rem;
			width: 56px;
			height: 64px;
			font-size: 18px;
			text-align: center;

			&:focus {
				outline: none;
				border-color: var(--Avvic-pry);
			}
		}
	}
	.otp-resend-link {
		a {
			color: var(--Avvic-pry);
			font-size: 1.2em;
		}
	}

	button {
		width: 20rem;
		height: 4rem;
		background-color: var(--Avvic-pry);
		color: white;
		border: none;
		border-radius: 100px;
		padding: 11.5px, 24px, 11.5px, 24px;
		font-size: 1.2em;
		cursor: pointer;

		&:hover {
			background-color: var(--Avvic-hover-purple);
		}
	}
	@media (max-width: 768px) {
		.card {
			width: 85%;

			h2 {
				font-size: 1.12rem;
				color: var(--AvvicBlack);
			}
			p {
				font-size: 0.85em;
				color: var(--AvvicBlack);
			}
		}
		.otp-container {
			gap: 0.45rem;
			.otp-input {
				width: 32px;
				height: 39px;
				font-size: 16px;
			}
		}
		.otp-resend-link {
			a {
				font-size: 0.85rem;
			}
		}
		button {
			width: 12rem;
			height: 3rem;
			border-radius: 100px;
			padding: 8px, 16px, 8px, 16px;
			font-size: 1em;
			cursor: pointer;

			&:hover {
				background-color: var(--Avvic-hover-purple);
			}
		}
	}

	@media (min-width: 769px) and (max-width: 855px) {
		.card {
			width: 70%;

			h2 {
				font-size: 1.2rem;
			}
			p {
				font-size: 1em;
			}
		}
		.otp-container {
			gap: 0.55rem;
			.otp-input {
				width: 32px;
				height: 39px;
			}
		}
		.otp-resend-link {
			a {
				font-size: 1.1rem;
			}
		}
	}
}
