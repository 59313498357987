.formContainer {
	grid-column: span 9;
	display: flex;
	justify-content: center;
	scrollbar-width: none;
	overflow: auto;
	height: 100vh;
	.form-wrapper {
		flex-basis: 60%;
	}
	.form-heading {
		text-align: center;
		margin: 3rem 0rem;
	}
	.form-textarea {
		border-radius: 8px;
		border: 1px solid var(--Avvic-light-grey);
		resize: none;
		width: 100%;
		padding: 1rem;
		margin: 0.5rem 0rem;
	}
	.two-btn-div {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
		margin-top: 0.5rem;
		margin-bottom: 5rem;
	}
	.form-next-btn {
		width: 100%;
		border: none;
		background-color: var(--AvvicDCyan);
		color: var(--AvvicWhite);
		padding: 0.7rem;
	}
	.form-next-btn:disabled {
		background-color: rgba(13, 153, 153, 0.5);
	}

	.form-prev-btn {
		background-color: var(--AvvicWhite);
		border: 1px solid var(--Avvic-pry);
		color: var(--Avvic-pry);
	}
	.form-checkbox-wrapper {
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		list-style: lower-roman;
	}

	.form-checkbox {
		display: flex;
		gap: 1rem;
	}
	.checkbox {
		width: fit-content;
	}
	.green {
		color: var(--Avvic-pry);
	}
	.label-heading {
		font-size: 18px;
		margin: 2rem 0rem;
	}
	.loan-request-history-container {
		display: grid;
		place-content: center;
		button {
			border: none;
			background-color: transparent;
			padding: 0;
		}
	}
	.loan-request-history {
		padding: 2rem 4rem;
		display: flex;
		flex-direction: column;

		gap: 2rem;
		h3 {
			margin-bottom: 2rem;
		}

		.loan-portfolio-container {
			background-color: var(--very-light-grey);
			padding: 2rem;
			border-radius: 24px;
			.loan-portfolio-wrapper {
				display: flex;
				gap: 0.5rem;
				flex-wrap: wrap;
				.loan-detail {
					border: 1px solid var(--AvvicVDGrey);
					border-radius: 8px;
					padding: 1rem;
					height: fit-content;
					p {
						margin: 0.3rem;
					}
				}
			}
			.btn-div {
				display: flex;
				justify-content: flex-end;
				button {
					border: none;
					background-color: var(--AvvicDCyan);
					color: var(--AvvicWhite);
					padding: 0.7rem;
				}
			}
		}
		.request-loan-container {
			width: 70%;
		}
	}
}
