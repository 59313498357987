@use "../globals";
#getapp {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 3%;

	@media (min-width: 64.1em) {
		padding: var(--whiteSpace);
		display: grid;
		grid-template-columns: 40% 60%;
	}
	h2 {
		color: var(--AvvicDBlue);
		font-weight: var(--headings-font-weight);
	}
	#app-link-div {
		display: flex;
		gap: 1rem;
	}

	#coming {
		position: absolute;
		width: max-content;
		top: 10%;
		left: 6%;
		padding: 0.3em 1em;
		border-radius: 4em;
		background: rgba(#fa4b4b, 0.4);
		color: var(--Avvic-red);

		@media (max-width: 1024px) {
			position: relative;
			margin: 0 auto;
			top: 0;
			left: 0;
		}
		@media (max-width: 768px) {
			position: relative;
			margin: 0;
			top: 0;
			left: 0;
		}
	}

	#get-app-text {
		align-self: center;
		@media (min-width: 49.75em) {
			padding: 2rem;
		}
	}
	#app-img-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 0.2rem;
		max-width: 100%;
		img {
			border-radius: var(--borderRadius);
			transform: scale(0.9);
		}
	}
	#app-img-grid > img:nth-child(2) {
		transform: scale(1.5);
		border: none;
		animation: floating 2s ease-in-out infinite alternate;
	}

	@keyframes floating {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(-5px);
		}
	}
}
