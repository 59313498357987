@use "../globals";

#footer-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: var(--AvvicWhite);
	font-size: var(--small-text);
	padding: 3%;

	a:hover {
		color: var(--AvvicDCyan);
		transition: padding 0.3s ease-in-out;
	}
	p,
	a {
		margin: 0;
	}

	h4 {
		color: var(--AvvicBlack);
		margin-bottom: 0.5rem;
	}
	.logo-div {
		padding-inline: 2%;

		img {
			max-width: 110px;
			@media (min-width: 64.1em) {
				max-width: 150px;
			}
		}
	}
	.text-div {
		display: grid;
		gap: 1rem;
		padding: 3%;
		@media (min-width: 49.75em) {
			padding: 1% 4% 4% 4%;
			grid-template-columns: repeat(5, 1fr);
		}
		.subscribe {
			grid-column: span 2;
			p {
				padding-bottom: 0.7rem;
			}
		}
		#input-link {
			font-size: 0.8em;
			margin-top: 0.7rem;
		}
	}
	.input-div {
		display: flex;
		gap: 2%;
	}
	button {
		background-color: transparent;
		border: 1px solid var(--textColor);
		padding: 2%;
		font-size: var(--btn-text-size);
		font-weight: 400;
		color: var(--AvvicBlack);
	}
	input {
		background-color: transparent;
		border: 1px solid var(--textColor);
		font-size: var(--text-font);
		line-height: 1.7;
		width: 60%;
		padding: 2%;
		border-radius: 5px;
	}
	.footer-contact {
		display: flex;
		flex-direction: column;
		gap: 0.7rem;
	}
	a {
		display: inline-block;
	}
	#social-links {
		display: flex;
		gap: 1rem;
		a {
			display: inline-block;
		}
	}
	#footer-links {
		display: flex;
		flex-direction: column;
		gap: 0.7rem;

		a {
			display: inline-block;
			text-decoration: none;
		}
	}
	.border-div {
		border-bottom: 1px solid var(--textColor);
		margin-top: 2%;
		@media (min-width: 49.75em) {
			margin-left: 2rem;
			margin-right: 2rem;
			padding-inline: 12%;
		}
	}
	#policy-div-wapper {
		display: flex;
		justify-content: space-between;
		padding-top: 1%;
		padding-inline: 3%;
	}
	#policy-div {
		display: flex;
		gap: 1rem;
	}
}
