@use "../globals";
.otp-modal {
	background-color: var(--AvvicWhite);
	padding: 2rem;
	border-radius: 8px;
	h3 {
		color: var(--AvvicVDGrey);
	}
	text-align: center;
	.close-btn-wrapper {
		display: flex;
		justify-content: flex-end;
		button {
			background-color: transparent;
			padding: 0;
		}
	}
	.otp-input-wrapper {
		display: flex;
		gap: 0.7rem;
		justify-content: center;
		.otp-input {
			width: 32px;
			padding: 10px;
			background-color: #eff0f1;
			border: none;
			text-align: center;
			border-radius: 8px;
			&::placeholder {
				text-align: center;
				color: var(--AvvicVDGrey);
				font-weight: bold;
			}
			@media (min-width: 49.75em) {
				width: 56px;
				height: 64px;
			}
		}
		.otp-input:focus {
			outline: none !important;
			border: 1px solid var(--Avvic-pry);
			box-shadow: 0 0 10px rgba(45, 45, 45, 0.1);
		}
		.otp-input.input-error {
			border: 0.5px solid var(--Avvic-red);
		}
	}
	.green {
		color: var(--Avvic-pry);
		cursor: pointer;
	}
	button {
		border: none;
		background-color: var(--AvvicDCyan);
		color: var(--AvvicWhite);
		padding: 0.7rem;
	}
}
.otp-modal.open {
	animation: zoomInFromNothing 1s both;
}
