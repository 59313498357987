@use "../globals";
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgb(10, 10, 10, 0.6);
	display: grid;
	place-content: center;
	z-index: 99;
	overflow: hidden;
	.modal-wrapper {
		background-color: var(--AvvicWhite);
		padding-inline: 1%;
		border-radius: 5px;
		margin-inline: auto;
		max-height: 95vh;
		width: 95vw;
		overflow-y: auto;
		animation: slideIn 0.4s ease-in-out forwards;
		@media (min-width: 64.1em) {
			width: 60vw;
		}
		.close-btn-wrapper {
			display: flex;
			justify-content: flex-end;

			button {
				border: none;
				cursor: pointer;
				background-color: transparent;
			}
		}
	}
}

// Modal.scss

.modal-container {
	.custom-modal-content {
		position: relative;
		width: auto;
		border-radius: 15px;
		height: auto;
		background-color: var(--AvvicWhite);
		padding: 2%;
	}
}
