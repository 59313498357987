@use "../globals";
.notification-container {
	grid-column: span 9;
	min-height: 100vh;
	padding: 2rem 4rem;
	.notif-top {
		display: flex;
		justify-content: space-between;
		.notif-top-btn {
			background-color: transparent;
			border: none;
			align-items: center;
			font-size: 1rem;
			border-radius: 0;
			padding: 0.5;
		}
		.notif-top-left {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			.notif-top-btn {
				width: 6rem;
			}
		}
		.notif-top-btn.active {
			border-bottom: 1.5px solid #d81b60;
			border-radius: 4px;
		}
		.notif-top-btn.unread {
			display: flex;
			gap: 0.5rem;
			align-items: center;
		}
		.notif-badge {
			display: grid;
			place-content: center;
			background-color: #d81b60;
			width: 20px;
			height: 20px;
			color: white;
			border-radius: 50%;
			padding: 0.7rem;
		}
	}
	.all-notif-section {
		padding: 2rem 0rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		.notif-wrapper {
			display: grid;
			grid-template-columns: repeat(10, 1fr);
			background-color: var(--very-light-grey);
			border-radius: 12px;

			padding: 0.5rem 1rem;
			width: 95%;
			margin: 0 auto;
			.notif-icon {
				width: 3rem;
				height: 3rem;
				border-radius: 50%;
				display: grid;
				place-content: center;
			}
			.notif-msg {
				grid-column: span 8;
				.notif-title {
					color: var(--AvvicDBlue);
				}
				.notif-title,
				.notif-desc {
					padding: 0;
					margin: 0;
				}
			}
			.notif-timestamp {
				display: flex;
				gap: 0.2rem;
				justify-self: center;
				small {
					margin-top: 0.2rem;
				}
				.timestamp {
					display: none;
				}
				.timestamp.unread {
					display: block;
					background-color: #d81b60;
					width: 0.2rem;
					height: 1.5rem;
					border-radius: 4px;
				}
			}
		}
	}
}
