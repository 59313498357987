@use "../globals";

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	.logout-card {
		width: 630px;
		height: 186px;
		padding: 32px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		border-radius: 24px;

		@media screen and (max-width: 767px) {
			width: 93%;
		}
		.logout-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
		p {
			font-size: 24px;
			margin: 16px 0;

			@media screen and (max-width: 767px) {
				font-size: 16px;
				margin-top: 12px;
				margin-bottom: 23px;
			}
		}
		.btn-container {
			display: flex;
			flex-direction: row;
			gap: 1.5rem;
			@media screen and (max-width: 767px) {
				gap: 1rem;
			}
			.btn-logout,
			.btn-cancel {
				width: 269px;
				height: 52px;
				padding: 10px 16px;
				font-size: 16px;
				cursor: pointer;
				@media screen and (max-width: 767px) {
					width: 132px;
					height: 45px;
					padding: 2% 5%;
				}
			}
			.btn-logout {
				background-color: var(--Avvic-pry);
				color: #fff;
				border: none;
			}

			.btn-cancel {
				background-color: var(--AvvicWhite);
				color: #000;
				border: 1px solid var(--Avvic-light-grey);
			}
		}
	}
}
