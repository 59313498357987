@use "../globals";

.calculator {
	background-color: #f6fefe;
	padding: 10% 5%;
	text-align: center;
	margin-top: 3%;
	width: 100%;

	@media (min-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5% 5%;
		text-align: left;
	}

	@media (min-width: 1024px) {
		padding: 5% 10%;
		text-align: left;
	}

	.form-heading {
		@media (min-width: 768px) {
			font-size: 24px;
		}

		@media (min-width: 1024px) {
			font-size: 2em;
		}

		@media (min-width: 1280px) {
			font-size: 2.9em;
		}
	}

	.form-description {
		@media (min-width: 768px) {
			font-size: 14px;
		}

		@media (min-width: 1024px) {
			font-size: 1em;
		}

		@media (min-width: 1280px) {
			font-size: 1.2em;
		}
	}

	div {
		position: relative;

		.form-label {
			margin-bottom: 1%;
		}

		.input-content {
			position: relative;
			width: 100%;
			display: flex;
			// align-items: center;
			// justify-content: space-between;
			border: 0.1em solid var(--Avvic-light-grey);
			border-radius: 0.3em;
			overflow: hidden;

			.form-input {
				padding: 0.6em 1em;
				border: none;
				outline: none;
				resize: none;
				width: 100%;
				font-size: 1em;
			}

			.currency {
				width: 25%;
				padding: 0 1em;
				background: var(--AvvicDCyan);
				margin-left: auto;
				color: var(--AvvicWhite);
				display: flex;
				align-items: center;
			}
		}
	}
}

.interest-section {
	background: var(--AvvicWhite);
	// width: 100%;
	height: 100%;
	padding: 5em 2em;
	border-radius: 1em;
	box-shadow: 0.2em 0.6em 2em var(--Avvic-light-grey);
	display: flex;
	flex-direction: column;
	gap: 1em;

	@media (max-width: 768px) {
		margin-top: 5%;
	}

	.interest-body {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		font-weight: 600;
		line-height: 1em;

		span {
			padding: 0.5em 1em;
			border: 0.02em solid var(--AvvicCyan);
			border-radius: 0.5em;
			font-weight: 400;
		}
	}

	.rates {
		display: flex;
		flex-direction: column;
		gap: 0.5em;

		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}
