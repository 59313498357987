// /* Transaction History  */
.transaction-history-div {
	grid-column: span 9;
	min-height: 100vh;
	border-radius: 24px;
	background: rgba(239, 240, 241, 0.6);
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
	.transaction-history-content {
		padding: 32px;
	}

	.portfolio-heading {
		color: var(--neutral-black, #212121);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%;
		margin-bottom: 32px;
	}

	.portfolio-details {
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		gap: 20px;
		align-self: stretch;
		flex-wrap: wrap;
	}

	.deposit-amount-container,
	.maturity-value-container {
		width: 240px;
		height: 102px;
		color: var(--neutral-black, #212121);
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 38.4px;
		border-radius: 8px;
		border: 1px solid var(--textfield-placeholder, #878e9d);
		background: rgba(2, 11, 50, 0.04);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.deposit-amount-heading,
	.maturity-value-heading,
	.proposed-heading,
	.renewal-heading,
	.interest-heading,
	.accrued-heading,
	.payment-heading {
		color: var(--Gray-Text, #667085);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 25.6px;
		margin: 2px;
	}
	.amount-value,
	.maturity-value,
	.proposed-value,
	.renewal-value,
	.interest-value,
	.accrued-value,
	.payment-value {
		color: var(--neutral-black, #212121);
		font-size: 19px;
		font-style: normal;
		font-weight: 700;
		line-height: 38.4px;
		margin: 0px;
	}

	.proposed-container {
		padding: 6px;
		border-radius: 8px;
		border: 1px solid var(--textfield-placeholder, #878e9d);
		background: rgba(2, 11, 50, 0.04);
		width: 168px;
		height: 102px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.renewal-container {
		width: 354px;
		height: 102px;
		border-radius: 8px;
		border: 1px solid var(--textfield-placeholder, #878e9d);
		background: rgba(2, 11, 50, 0.04);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.interest-container {
		width: 191px;
		height: 102px;
		border-radius: 8px;
		border: 1px solid var(--textfield-placeholder, #878e9d);
		background: rgba(2, 11, 50, 0.04);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.accrued-container {
		width: 200px;
		height: 102px;
		border-radius: 8px;
		border: 1px solid var(--textfield-placeholder, #878e9d);
		background: rgba(2, 11, 50, 0.04);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.payment-container {
		height: 102px;
		width: 229px;
		border-radius: 8px;
		border: 1px solid var(--textfield-placeholder, #878e9d);
		background: rgba(2, 11, 50, 0.04);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.liquidate-div {
		display: flex;
		justify-content: space-between;
		margin-top: 32px;
		margin-right: 193px;
	}
	.horizontal-rule {
		width: 1168.007px;
		margin-bottom: 40px;
		margin-top: 40px;
		height: 1px;
		background: #d0d5dd;
	}
	.liquidate-btn,
	.add-new-deposit {
		display: flex;
		width: 320px;
		padding: 11.5px 12px;
		justify-content: center;
		align-items: center;
		gap: 4px;
		border-radius: 100px;
		border: 1px solid;
		box-shadow: 0px 0.96021px 1.92041px 0px rgba(16, 24, 40, 0.05);
		font-size: 14px;
		color: red;
		font-style: normal;
		font-weight: 600;
		line-height: 160%;
	}

	a {
		text-decoration: none;
		color: #0d9999;
	}

	.add-new-deposit {
		border: 1px solid #0d9999;
		color: #fff;
		background: #0d9999;
	}
	.savings-container {
		background: #110b46;
		border-radius: 12px;
		width: 1000px;
		.savings-content {
			padding: 16px;
		}
		.savings-flex {
			display: flex;
			gap: 24px;
			justify-content: space-between;
		}
		.savings-heading {
			color: white;
			font-size: 24px;
			font-weight: 700;
			width: 65%;
			margin-bottom: 14px;
			margin-top: 40px;
		}
		.interest-rate {
			background: #c82b87;
			color: white;
			font-size: 10px;
			font-weight: 400;
			text-align: center;
			width: 70px;
			margin-left: 69px;
			text-transform: uppercase;
			border-radius: 5px;
			margin-bottom: 16px;
		}
	}
	.interest-content {
		padding: 8px;
	}
}
.twenty-percent {
	font-size: 24px;
	font-weight: 700;
	text-transform: uppercase;
}
.interest-rate-span {
	color: white;
	font-size: 7px;
	font-weight: 700;
	text-transform: uppercase;
}
.savings-subtext {
	color: white;
	font-size: 20px;
	font-weight: 400;
}
.create-account-btn {
	color: #0d9999;
	height: 48px;
	font-size: 18px;
	width: 290px;
	border: white;
}
