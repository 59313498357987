@use "../globals";

.password-update-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 5rem;
	align-items: center;
	height: auto;

	lable {
		line-height: 34px;
	}
	@media (max-width: 425px) {
		h2 {
			font-size: 24px;
		}
		p {
			font-size: 14px;
		}
	}

	.Password-update-input {
		width: 23rem;
		height: 2.5rem;
		padding: 0.7rem;
		border-radius: 8px;
		border: 1px solid var(--Avvic-light-grey);
		margin-bottom: 1.6rem;
		@media (max-width: 425px) {
			width: 16rem;
			height: 2rem;
			margin-bottom: 1.2rem;
		}
	}
	.password-submit-btn {
		margin-left: 6rem;
		border: 1px solid #0d9999;
		@media (max-width: 425px) {
			margin-left: 0;
		}
	}
	.error {
		color: red;
	}
	.password-update-success-modal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-80%, -30%);
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 999;
		@media (max-width: 768px) {
			transform: translate(-60.9%, -35%);
		}
		.modal-content {
			background-color: var(--AvvicWhite);
			width: 35%;
			height: auto;
			color: black;
			box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
			padding: 3rem;
			font-size: 16px;
			border-radius: 15px;
			text-align: center;
			@media (max-width: 768px) {
				width: 73%;
			}
		}
	}
}
