@use "../globals";

#why-choose-us-section {
	background: linear-gradient(90deg, #fffeff 0%, #d7fffe 100%);
	padding: calc(var(--whiteSpace)) 2em;

	@media (min-width: 49.75em) {}

	h2 {
		text-align: center;
		color: var(--AvvicDBlue);
	}

	p {
		text-align: center;
		padding: 3%;

		@media (min-width: 49.75em) {
			padding: 3% 15%;
		}
	}

	.why-choose-us-slider {
		overflow: hidden;
		place-items: center;
		transition: transform 0.5s ease-in-out;

		img {
			border-radius: 8px;
			height: 15.7rem;
		}
	}

	.slider-inner {
		display: flex;
		gap: 1rem;
		animation: slide-animation 40s linear infinite;
	}

	.why {
		border-radius: 10px;
		padding-inline: 1rem;

		@media (min-width: 49.75em) {}
	}

	@keyframes slide-animation {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(calc(-250px * 10));
		}
	}
}

.slider-container {
	position: relative;
	max-width: 1280px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	.mySlider {
		position: relative;
		width: 100%;

		@media (max-width: 768px) {
			margin: 0;
		}
	}

	.swiper-wrapper {
		width: 100%;
		margin: 0 2em 0 2.26em;

		@media (max-width: 768px) {
			margin: 0;
		}
	}
}