@use "../globals";
.mfb-dashboard {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	position: relative;
	max-height: 100vh;
	main {
		overflow: hidden;
		max-height: 100vh;
		grid-column: span 9;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		header {
			.search-wrapper {
				display: none;
				@media (min-width: 49.75em) {
					display: block;
					position: relative;
					flex-basis: 30%;
					img {
						position: absolute;
						top: 0.5rem;
						left: 1rem;
					}
					input {
						width: 100%;
						padding: 0.7rem 0.7rem 0.7rem 2.5rem;
						border-radius: 8px;
						border: 1px solid #2f515c1f;
						box-shadow: 2px 2px 4px #2f515c1f;
					}
				}
			}

			.top-nav-user {
				display: flex;
				justify-content: space-between;
				.top-notification {
					margin-bottom: 40px;
					margin-right: 40px;
				}
				.intro-text-div {
					h2 {
						color: var(--AvvicDBlue);
					}
				}
			}
		}
		.scroll-section {
			grid-column: span 2;
			overflow: hidden;
			position: sticky;
			top: 0;
			height: 100vh;
			position: relative;
		}
		.dashboard-wrapper {
			position: absolute;
			top: 0;
			height: 100%;
			width: 102%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			gap: 2.5rem;
			padding: 2rem 2.1rem 2rem 2rem;
			overflow-y: auto;
			overflow-x: hidden;

			.dashboard {
				.mfb-services {
					display: flex;
					flex-wrap: wrap;
					gap: 1rem;
					@media (min-width: 49.75em) {
						display: flex;
						display: grid;
						grid-template-columns: repeat(4, 1fr);
						grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
					}
					.mfb-service-link {
						text-decoration: none;
						border-radius: 8px;
						padding: 1rem 0.5rem;
						box-shadow: 2px 2px 6px #2f515c1f;
						.service-img-div {
							display: flex;
							justify-content: space-between;
							align-items: flex-start;
							button {
								padding: 0;
								border-radius: 0;
								background-color: transparent;
								border: none;
							}
							.service-icon-btn {
								padding: 0.3rem;
								border-radius: 5px;
							}
						}
						&:first-child {
							background-color: #0d99993d;
							color: #0d9999;
							.service-icon-btn {
								background-color: #0d99993d;
							}
						}
						&:nth-child(2) {
							background-color: #12b82252;
							color: #12b822;
							.service-icon-btn {
								background-color: #12b82252;
							}
						}
						&:nth-child(3) {
							background-color: #e3fdfd;
							color: #0d9999;
							.service-icon-btn {
								background-color: #e3fdfd;
							}
						}
						&:nth-child(4) {
							background-color: #12cccc52;
							color: #ef0eef;
							.service-icon-btn {
								background-color: #12cccc52;
							}
						}
						&:last-child {
							background-color: #ef0eef3d;
							color: #ef0eef;
							.service-icon-btn {
								background-color: #ef0eef3d;
							}
						}
					}
				}
			}
			.savings-container {
				background: #110b46;
				border-radius: 12px;
				.savings-content {
					padding: 16px;
				}
				.savings-flex {
					display: flex;
					gap: 24px;
					justify-content: space-between;
				}
				.savings-heading {
					color: white;
					font-size: 24px;
					font-weight: 700;
					width: 65%;
					margin-bottom: 14px;
				}
				.interest-rate {
					background: #c82b87;
					color: white;
					font-size: 10px;
					font-weight: 400;
					text-align: center;
					width: 70px;
					margin-left: 30px;
					text-transform: uppercase;
					border-radius: 5px;
					margin-bottom: 16px;
				}
				.interest-content {
					padding: 8px;
				}
			}
			.twenty-percent {
				font-size: 24px;
				font-weight: 700;
				text-transform: uppercase;
			}
			.interest-rate-span {
				color: white;
				font-size: 7px;
				font-weight: 700;
				text-transform: uppercase;
			}
			.savings-subtext {
				color: white;
				font-size: 16px;
				font-weight: 400;
			}
			.create-account-btn {
				color: #0d9999;
				font-size: 18px;
				width: 215px;
				border: white;
			}
		}
		.see-all-btn-warapper {
			display: flex;
			justify-content: flex-end;
			.see-all-btn {
				background-color: transparent;
				color: var(--Avvic-pry);
				border: 2px solid var(--Avvic-pry);
				width: 8.5rem;
				margin-top: 1rem;
			}
		}
		aside {
			padding-inline: 2rem;
			.cards-wrapper {
				display: none;
				@media (min-width: 49.75em) {
					display: flex;
					background-color: #ecfdfd;
					gap: 1rem;
					flex-direction: column;
					padding: 2rem 1rem;
					border-radius: 8px;
					margin-top: 120px;
					h3 {
						color: var(--AvvicDBlue);
					}

					img {
						border-radius: 8px;
					}
					.add-new-card {
						background-color: transparent;
						border: 1px solid var(--Avvic-pry);
						color: var(--Avvic-pry);
						border-radius: 20px;
					}
				}
			}

			.add-card {
				display: flex;
				align-items: center;
			}

			.scroll-container {
				display: flex;
				justify-content: center;
				gap: 10px;
			}

			.prev-scroll {
				display: flex;
				gap: 40px;
				.right-chevron {
					transform: rotate(180deg);
				}
			}

			.next-scroll {
				display: flex;
				gap: 40px;
			}

			.add-btn-image {
				color: #0d9999;
			}

			.add-card-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				background-color: transparent;
				color: #0d9999;
				border: none;
				padding: 0.7rem;
				width: 100%;
			}
		}
	}
	.request-loan-container {
		background: linear-gradient(270deg, #ebfcfc 0%, #f7f9f9 100%);
		color: white;
		border-radius: 12px;
		height: 185px;

		.loan-flex {
			display: flex;
			gap: 24px;
		}
		.loan-texts {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
		.request-loan-content {
			padding: 1rem;
		}
		.loan-heading {
			color: #212121;
			font-size: 24px;
			display: flex;
			justify-content: space-between;
			font-weight: 700;
		}
		.loan-details {
			color: #212121;
			font-size: 18px;
			font-weight: 400;
			margin-top: 10px;
			margin-bottom: 18px;
		}
	}
	a {
		text-decoration: none;
	}
	.request-loan-btn {
		font-size: 18px;
		background: #0d9999;
		font-weight: 600;
		border: white;
		outline: none;
		color: white;
		width: 200px;
		padding: 11.5px 24px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 48px;
	}
}
