@use "../globals";

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 122%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	background: white;
	padding: 20px;
	height: 100%;
	width: 67%;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	text-align: center;
	overflow: scroll;
}

.modal-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.liquidate-heading {
		color: var(--neutral-black, #212121);
		text-align: center;
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%;
	}

	#credit-account {
		width: 20em;
		border: 0.02em solid var(--Avvic-grey);
		border-radius: 0.5em;
		background: var(--Avvic-light-grey);
		padding: 0.5em 0.5em;
		color: var(--AvvicBlack);
	}

	.liquidate-info {
		color: #202020;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%;
	}

	.close-modal {
		margin-left: 50rem;
		margin-bottom: -34px;
	}

	input::placeholder {
		overflow: hidden;
		color: var(--textfield-placeholder, #878e9d);
		text-overflow: ellipsis;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		padding-left: 10px;
	}

	.liquid-label {
		margin-right: 217px;
		color: var(--neutral-black, #212121);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
	}

	.textarea {
		height: 143px;
		width: 400px;
		padding-bottom: 100px;
		padding-left: 10px;
		border-radius: 8px;
		border: 1px solid var(--textfield-stroke, #d0d5dd);
		background: var(--White, #fff);
		box-shadow: 0px 1.04009px 2.08017px 0px rgba(16, 24, 40, 0.05);
	}

	.Proposed-duration {
		margin-right: 160px;
		color: var(--neutral-black, #212121);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
	}

	.rate {
		color: var(--neutral-black, #212121);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
		margin-right: 132px;
	}

	.maturity-date {
		color: var(--neutral-black, #212121);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
		margin-right: 290px;
	}

	.condition-div {
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: center;
		.conditions {
			margin: 0;
			width: 100%;
			color: black;
			font-size: 14px;
		}
		.condition {
			color: var(--neutral-black, #212121);
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%;
		}
		.condition-span {
			color: #0d9999;
			text-decoration: none;
		}
	}

	.select-account {
		display: flex;
		gap: 10px;
		margin-top: 42px;
		align-items: center;
		justify-content: center;
	}

	.choose-account {
		color: #667085;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 25.6px;
	}

	.bank-select {
		width: 190px;
		height: 40px;
		border-radius: 8px;
		border: 1px solid #d0d5dd;
		background: #fff;
		box-shadow: 0px 1.04009px 2.08017px 0px rgba(16, 24, 40, 0.05);
	}
	.place-info {
		color: #202020;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		margin-top: 42px;
	}
	.duration,
	.amount2 {
		border-radius: 8px;
		border: 1px solid var(--textfield-stroke, #d0d5dd);
		background: var(--White, #fff);
		box-shadow: 0px 1.04009px 2.08017px 0px rgba(16, 24, 40, 0.05);
		padding: 5px;
		width: 400px;
		margin-top: 8px;
		margin-bottom: 8px;
		height: 48px;
	}
	.rate-input {
		border-radius: 8px;
		border: 1px solid var(--textfield-stroke, #d0d5dd);
		background: var(--background-gray, #eff0f1);
		box-shadow: 0px 1.04009px 2.08017px 0px rgba(16, 24, 40, 0.05);
		padding: 5px;
		width: 400px;
		height: 48px;
		margin-bottom: 10px;
		margin-top: 8px;
	}

	.return-heading {
		color: var(--Gray-Text, #667085);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		margin-right: 18px;
		font-weight: 500;
		margin-bottom: 0px;
		line-height: 25.6px;
	}

	.return-choice {
		margin-bottom: 24px;
		margin-top: 18px;
		display: flex;
		flex-direction: column;
		align-items: baseline;
		gap: 12px;
		color: var(--neutral-black, #212121);
	}
	.trial {
		margin-top: 24px;
	}
	.content {
		margin-right: 90px;
		font-size: 17px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.continue-text {
		padding: 11.5px 24px;
		width: 400px;
		height: 52px;
		color: var(--White, #fff);
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%;
		border-radius: 100px;
		border: 1px solid var(--Avvic-Blue-Pri, #0d9999);
		background: var(--Avvic-Blue-Pri, #0d9999);
		box-shadow: 0px 0.96021px 1.92041px 0px rgba(16, 24, 40, 0.05);
	}

	.success-headline {
		color: var(--Avvic-Blue-Pri, var(--Avvic-pry, #0d9999));
		text-align: center;
		font-size: 50px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.success-msg-paragraph {
		color: #000;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		margin: 15px 207px;
	}

	.paragraph-span {
		text-decoration: none;
		color: #0d9999;
	}
	.success-home-btn {
		width: 400px;
		height: 50px;
		border-radius: 100px;
		border: 1px solid var(--Avvic-Blue-Pri, #0d9999);
		background: var(--Avvic-Blue-Pri, #0d9999);
		box-shadow: 0px 0.96021px 1.92041px 0px rgba(16, 24, 40, 0.05);
		color: var(--White, #fff);
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%;
	}

	.success-img-wrapper {
		display: flex;
		width: 30%;
		margin: 0 auto;
		justify-content: center;
	}
}
