@use "../globals";
.success-wrapper {
	background-color: var(--AvvicWhite);
	border-radius: 20px;
	padding: 2rem 0rem;
	.accounts-btn {
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%;
		width: 100%;
		height: 52px;
		color: white;
		border-radius: 100px;
		border: 1px solid var(--Avvic-Blue-Pri, #0d9999);
		background: var(--Avvic-Blue-Pri, #0d9999);
		box-shadow: 0px 0.96021px 1.92041px 0px rgba(16, 24, 40, 0.05);
	}
	@media (min-width: 49.75em) {
		padding: 2rem;
	}
	.success-img-wrapper {
		display: flex;
		width: 50%;
		margin: 0 auto;
		justify-content: center;
		#success-img {
			animation: zoomInFromNothing 1s both;
		}
	}
	h2 {
		text-align: center;
	}
	.success-text {
		color: var(--Avvic-Blue-Pri, var(--Avvic-pry, #0d9999));
		font-style: normal;
		line-height: normal;
		font-size: 60px;
		font-style: normal;
		font-weight: 600;
	}
	.success-message {
		color: #000;
		text-align: center;
		margin: 13px 0px;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
	}
}
