@use "../../globals";

.password-card-wrapper {
	position: relative;
	background: url("../../../../public/assets/portal-bg.png") center;
	background-size: cover;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.password-card {
		width: 464px;
		padding: 3.5%;
		border-radius: 16px;
		display: flex;
		height: auto;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		align-items: center;
		background-color: var(--AvvicWhite);
		width: 90%;
		@media (min-width: 49.75em) {
			width: 60%;
		}
		@media (min-width: 64.1em) {
			width: 30%;
		}

		.logo-wrapper {
			display: flex;
			justify-content: center;
			.logo-text {
				color: var(--Avvic-pry);
				font-size: 1.4rem;
			}
		}
		.example-email {
			margin-top: -1.58rem;
			p {
				line-height: 25.6px;
				color: var(--textColor);
			}
		}
		.password-update-text-wrapper {
			margin-top: -1.8rem;
			color: var(--AvvicBlack);
			padding: 0.7rem;
			font-size: 18px;
			h3 {
				text-align: center;
				font-size: var(--large-h3-text-size);
			}
		}
		.card-content {
			form {
				display: flex;
				flex-direction: column;
				margin-top: -3rem;
				label {
					line-height: 3rem;
				}
				input[type="text"],
				input[type="password"] {
					width: 100%;
					height: 3rem;
					padding: 0.7rem;
					border-radius: 8px;
					border: 1px solid var(--Avvic-light-grey);
					margin-bottom: 1.3rem;
				}
				.eye-btn1-wrapper,
				.eye-btn2-wrapper {
					position: relative;
					.eye-btn1,
					.eye-btn2 {
						position: absolute;
						border: none;
						padding: 0;
						margin: 0;
						right: 0.9rem;
						background-color: transparent;
						cursor: pointer;
					}
				}
				.eye-btn1 {
					top: -3.5rem;
				}
			}

			.eye-btn2 {
				top: -3.4rem;
			}
			.continue-btn {
				max-width: 400px;
				min-width: 100%;
				height: 3.2rem;
				border: none;
				border-radius: 100px;
				background-color: var(--AvvicDCyan);
				color: var(--AvvicWhite);
				font-size: var(--btn-text-size);
				&:hover {
					background-color: var(--Avvic-hover-purple);
				}
			}
		}

		.error {
			color: red;
			font-size: 14px;
			text-align: left;
			margin-top: 5px;
		}

		.modal {
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 999;

			p {
				background-color: var(--AvvicWhite);
				width: 40%;
				height: auto;
				color: black;
				padding: 3rem;
				font-size: 16px;
				border-radius: 15px;
				text-align: center;
				@media (max-width: 768px) {
					width: 73%;
				}
			}
			.login-button {
				max-width: 400px;
				min-width: 100%;
				height: 3.2rem;

				margin-top: 10px;
				border: none;
				border-radius: 100px;
				padding: 2%;
				background-color: var(--AvvicDCyan);
				cursor: pointer;
				&:hover {
					background-color: var(--Avvic-hover-purple);
				}
				a {
					text-decoration: none;
					color: var(--AvvicWhite);
					font-size: var(--btn-text-size);
				}
				@media (max-width: 768px) {
					border-radius: 28px;
				}
			}
		}
	}
}

@media (max-width: 736px) and (orientation: landscape) {
	.password-card-wrapper {
		height: 190vh !important;
	}
}
