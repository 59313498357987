#career-wrapper {
	display: flex;
	flex-direction: column;
	gap: 3em;
	background-color: var(--AvvicWhite);
	@media (min-width: 49.75em) {
	}
	h2 {
		color: var(--AvvicDBlue);
	}
	#career-dev-text {
		padding: var(--whiteSpace);
		text-align: center;
	}

	#career-dev {
		padding: 0 2rem;
		text-align: center;
		// border: 1px solid red;

		@media (min-width: 49.75em) {
			padding: 2rem 5rem;
		}
		@media (min-width: 64.1em) {
			padding: 2rem 20rem 0rem 20rem;
		}
		h2,
		h3 {
			color: var(--AvvicDBlue);
			text-align: center;
			margin-bottom: 1rem;
		}
	}
	#team-dev {
		display: flex;
		flex-direction: column;
		// gap: 5rem;
		padding: 0% 5% 3% 5%;
		// border: 1px solid red;
		h3 {
			margin-bottom: 1rem;
		}
		#article-1 {
			display: flex;
			flex-direction: column-reverse;
			gap: 2rem;
			// padding: 2rem;
			@media (min-width: 49.75em) {
				display: grid;
				grid-template-columns: 50% 50%;
				gap: 0rem;
			}
			aside {
				align-self: center;
				text-align: center;
				@media (min-width: 60em) {
					padding-inline: 5rem;
					text-align: start;
				}
				div {
					padding-bottom: 1.5rem;
				}
			}
			img {
				@media (min-width: 60em) {
					max-width: 31rem;
				}
			}
		}
		#article-2 {
			display: flex;
			flex-direction: column-reverse;
			gap: 2rem;
			@media (min-width: 49.75em) {
				display: flex;
				flex-direction: column;
				// gap: 5rem;
			}
			aside{
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 2em 0 0 0;
			}
			#img-div {
				@media (min-width: 49.75em) {
					align-self: center;
					width: 80%;
					display: block;
					padding-inline: 15%;
				}
			}
		}
	}
	#team-dev-grid {
		display: grid;
		gap: 1.5rem;
		grid-template-columns: 1fr;
		@media (min-width: 49.75em) {
			grid-template-columns: repeat(4, 1fr);
		}

		h2,
		h3 {
			color: var(--AvvicDBlue);
		}
		img {
			border-radius: 8px;
			width: 100%;
		}
		.grid-div {
			display: grid;
			gap: 1.2rem;
			grid-column: span 2;
			@media (min-width: 49.75em) {
				grid-column: span 1;
			}
		}
		.grid-div-2 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-column: span 2;
			gap: 1.2rem;
			@media (min-width: 49.75em) {
				display: grid;
				grid-template-columns: 1fr;
				grid-column: span 1;
			}
		}

		.grid-col-span-2 {
			grid-column: span 2;
		}
		article {
			background-color: var(--AvvicPurple);
			padding: 1rem;
			border-radius: 8px;
		}
		article:nth-child(2) {
			background-color: var(--AvvicGreen);
			grid-column: span 2;
			grid-row: 2;
		}

		@media (min-width: 49.75em) {
			article:nth-child(2) {
				background-color: var(--AvvicGreen);
				grid-column: 4;
				// grid-row: 1;
				// grid-row-end: 3;
				// or grid-row-end: span 2;
				grid-row: 1/3;
			}
		}

		#team-dev-top-wrapper {
			display: flex;
			flex-direction: column-reverse;
			gap: 2rem;
			@media (min-width: 49.75em) {
				display: grid;
				grid-template-columns: 50% 50%;
			}
		}
		#team-dev-btm-wrapper {
			display: flex;
			// flex-wrap: wrap;
			gap: 2rem;
			@media (min-width: 49.75em) {
				display: grid;
			}
			.process-wrkEthics {
				display: grid;
				grid-template-columns: 50% 50%;
				gap: 3rem;
				padding: 7%;
				border-radius: 5px;
				position: relative;

				h3 {
					text-align: center;
				}

				img {
					border-radius: 8px;
					width: 100%;
					height: 110%;
				}
			}
		}
	}
	.image-container {
		padding: 2%;
		position: relative;
		display: inline-block;
		z-index: 1;
		img {
			display: block;
			min-height: 100%;
			width: auto;
		}
		#top-right {
			background-color: rgba(13, 153, 153, 0.2);
			width: 50%;
			position: absolute;
			right: 0;
			top: 0;
			height: 80%;
			z-index: -1;
		}
		#bottom-left {
			background-color: rgba(13, 153, 153, 0.2);
			width: 50%;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 20%;
			z-index: -1;
		}
	}

	#ethics-display {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 0.4em;
		max-width: 50em;
		margin: 1em 0;

		@media (max-width: 768px) {
			max-width: 100%;
			justify-content: center;
		}

		.ethics-span {
			display: flex;
			align-items: center;
			gap: 0.3em;
			width: max-content;
			padding: 0.3em 0.7em;
			border: 0.02em solid var(--Avvic-light-grey);
			border-radius: 0.2em;
		}
	}

	#magic {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 4em;
		// margin: -3.3em 0 0 0;
		padding: 0 0.625em;
		margin: -2em 0 0 0;

		h2{
			margin: 0 auto;
			text-align: center;
		}
	}

	#jobOpening {
		display: flex;
		flex-direction: column;
		background: #f5f5f5;
		padding: 1em 0;
		@media (min-width: 64.1em) {
			padding: 0 10rem;
		}
		h2,
		h3 {
			color: var(--AvvicDBlue);
		}
		h3 {
			margin: 1rem 0rem;
		}
		#job-opening-heading {
			padding-inline: 5%;
		}
	}
}
