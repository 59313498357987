@use "../globals";

.add-phone-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	@media (max-width: 30rem) {
		justify-content: flex-start;
	}

	label {
		line-height: 34px;
	}
	.add-phone-input-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: baseline;
		align-items: baseline;
		gap: 0.1%;
		.add-phone-input {
			width: 21rem !important;
			height: 2rem;
			padding: 1rem !important;
			border-radius: 8px;
			border: 1px solid var(--Avvic-light-grey);
			margin-bottom: 1rem;
			margin-right: 1rem;
			@media (max-width: 30rem) {
				width: 90% !important;
			}
		}
	}
	.add-icon-wrapper {
		left: -4.5rem;
		@media (max-width: 30.9rem) {
			left: -3rem;
		}
	}
	.add-phone-modal-success {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 999;
		p {
			background-color: var(--AvvicWhite);
			width: 35%;
			height: auto;
			color: black;
			padding: 3rem;
			font-size: 16px;
			border-radius: 15px;
			text-align: center;
			@media (max-width: 768px) {
				width: 73%;
			}
		}
	}
}
